import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './device-menu.less';

class DeviceMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const cancelActive = this.props.currentDetails.details !== undefined && Object.prototype.hasOwnProperty.call(this.props.currentDetails.details, 'id');
    return <div className={styles.main} style={
      {
        top: `${this.props.top}px`,
        left: `${this.props.left}px`,
      }
    } onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
    }}>
      <div className={styles.menu} onClick={() => {
        this.props.switchMenu(false);
        this.props.switchDeviceSet(true);
      }}>设置跳转
      </div>
      <div className={styles.line}/>
      <div className={styles.menu} onClick={() => {
        this.props.switchMenu(false);
        this.props.switchDeviceControl(true);
      }}>遥控配置
      </div>
      <div className={styles.line}/>
      <div className={`${styles.menu} ${cancelActive ? '' : styles.invalid}`} onClick={() => {
        if (cancelActive) {
          this.props.cancelDetail();
        }
      }}>取消绑定
      </div>
    </div>;
  }
}

DeviceMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  switchDeviceSet: PropTypes.func.isRequired,
  switchMenu: PropTypes.func.isRequired,
  switchDeviceControl: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  cancelDetail: PropTypes.func.isRequired,
  currentDetails: PropTypes.object.isRequired,
};

export default DeviceMenu;
