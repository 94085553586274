import TYPES from './device-menu.types';

const activeDeviceMenu = (status) => (dispatch) => {
  dispatch({
    type: TYPES.ACTIVE_DEVICE_MENU,
    data: status,
  });
};

export default {
  activeDeviceMenu,
};
