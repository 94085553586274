import deviceControlTypes from './device-control.types';

const activeDeviceControl = (status) => (dispatch) => {
  dispatch({
    type: deviceControlTypes.ACTIVE_DEVICE_CONTROL,
    data: status,
  });
};

export default {
  activeDeviceControl,
};
