import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './button.less';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  btnClick = () => {
    if (this.props.onClick !== undefined) {
      this.props.onClick();
    }
  };

  render() {
    return <div className={styles.main} style={
      {
        background: this.props.backgroundColor,
        width: this.props.width,
        fontSize: this.props.fontSize,
      }
    } onClick={this.btnClick}>
      {
        this.props.icon !== 'none' && <span className={styles.icon} style={
          {
            backgroundImage: `url(${require(`../../common/images/${this.props.icon}`)})`,
          }
        }/>
      }
      {
        this.props.text && <div style={{
          color: this.props.fontColor,
        }} className={styles.text}>
        {
          this.props.text
        }
      </div>
      }
    </div>;
  }
}

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  icon: 'submit_white.png',
  backgroundColor: '#4cb4e7',
  fontColor: '#fff',
  width: '1rem',
  fontSize: '0.16rem',
};

export default Button;
