/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { domToReact } from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import htmlDomParser from 'html-dom-parser';
import SvgAdaptor from '../svg-adaptor/svg-adaptor';
import Drawer from '../../containers/drawer/drawer-container';
import ContextMenu from '../../containers/context-menu/context-menu-container';
import DeviceMenu from '../../containers/device-menu/device-menu-container';
import SystemSet from '../../containers/system-set/system-set-container';
import DomRender from '../../components/dom-render/dom-render';
import SvgList from '../../components/svg-list/svg-list';

import Tools from '../../utils/tools';

import styles from './svg-loader.less';
import Api from '../../api/api';
import DrawerStyles from '../../entity/drawer';
import MessageStyles from '../../entity/message';
import CircleLoading from '../../components/loading/circle/circle';
import url from '../../api/url';
import DeviceSet from '../../containers/device-set/device-set-container';
import DeviceControl from '../../containers/device-control/device-control-container';

const grayColor = [0.299, 0.587, 0.114];
let regexp;
if (process.env.REACT_APP_OTTER === 'true') {
  regexp = /(\s*\d{1,3}\s*,){2}\s*\d{1,3}\s*/;
} else {
  regexp = /(?<=rgb(a)?\()(\s*\d{1,3}\s*,){2}\s*\d{1,3}\s*(?=\))/;
}

const getQueryString = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) {
    return decodeURI(decodeURI(unescape(r[2])));
  }
  return undefined;
};

const svgUrl = getQueryString('svg');
const detailsUrl = getQueryString('details');

class SvgLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgObj: undefined,
      svgConfig: undefined,
      filename: decodeURIComponent(escape(window.atob(decodeURIComponent(Tools.emptyObj(props.match.params.filename, ''))))),
      contextMenuX: 0,
      contextMenuY: 0,
      deviceMenuX: 0,
      deviceMenuY: 0,
      showSystemSet: false,
      ifEdit: props.match.params.edit && window.atob(Tools.removeSalt(props.match.params.edit)) === '1',
      loading: true,
      currentPjId: window.atob(Tools.emptyObj(props.match.params.projectId, '')),
      svgListStatus: false,
      detailTip: {
        show: false,
        position: {
          left: 0,
          top: 0,
        },
        content: '',
      },
    };
  }

  componentDidMount() {
    this.api = new Api();
    this.domRender = new DomRender();
    this.getConfig();
    this.overTasks = [];
    this.mount = true;
  }

  componentWillUnmount() {
    clearInterval(this.dataInterval);
    this.mount = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showPrepared !== this.props.showPrepared) {
      this.domRender.removeCurrent();
      this.props.switchDrawer(DrawerStyles.state.shrink);
      this.domRender.showPreparedDom(this.state.svgConfig.filter((svg) => svg.type !== 'system'), this.props.showPrepared);
    }
    if (prevProps.match !== this.props.match) {
      // this.setState({
      //   loading: true,
      //   filename: decodeURIComponent(escape(window.atob(decodeURIComponent(Tools.emptyObj(this.props.match.params.filename, ''))))),
      //   ifEdit: this.props.match.params.edit && window.atob(Tools.removeSalt(this.props.match.params.edit)) === '1',
      //   currentPjId: window.atob(Tools.emptyObj(this.props.match.params.projectId, '')),
      // }, () => {
      //   this.getConfig();
      // });
      window.location.reload();
    }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error);
    // eslint-disable-next-line no-console
    console.log(errorInfo);
  }

  getConfig = () => {
    if (svgUrl !== undefined && detailsUrl !== undefined) {
      this.getUrlConfig();
    } else if (this.state.filename !== undefined) {
      this.getRemoteConfig().then(() => {
        if (this.mount && this.state.svgConfig) {
          this.domRender.showPreparedDom(this.state.svgConfig.filter((svg) => svg.type !== 'system'), this.props.showPrepared);
          if (this.state.ifEdit) {
            setTimeout(() => {
              this.props.getSvgList(this.state.currentPjId, 'light');
            }, 500);
          }
        }
        // }
      });
    }
  };

  getUrlConfig = () => {
    const { filename } = this.state;
    return (
      Promise.all([new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 200);
      }), fetch(`http://192.168.0.100/svg/${encodeURIComponent(filename)}`).then((res) => res.text()), fetch(`http://192.168.0.100/svg/${encodeURIComponent(filename.substring(0, filename.lastIndexOf('.')))}.json`).then((res) => res.json())]).then((results) => {
        const svg = results[1];
        const details = results[2];
        const svgObj = domToReact(htmlDomParser(svg).map((svgItem) => {
          if (svgItem.name === 'svg') {
            svgItem.attribs.id = 'svg-panel';
            return {
              ...svgItem,
              children: svgItem.children.map((device, index) => this.formatDevice(device, index)),
            };
          }
          return svgItem;
        }));
        this.setState({
          svgObj,
          svgConfig: details,
          loading: false,
        }, () => {
          // eslint-disable-next-line no-console
          console.log('loading action has finished');
          this.getDeviceData();
        });
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.setState({
          loading: false,
        });
      })
    );
  };

  getRemoteConfig = () => {
    const { filename } = this.state;
    return Promise.all([this.api.sendRequest({
      url: `${url.GET_TARGET_SVG}?filename=${encodeURIComponent(window.btoa(unescape(encodeURIComponent(`${this.state.currentPjId}/svg/${filename}`))))}`,
      resultType: 'text',
      errorThrow: true,
    }), this.api.sendRequest({
      url: `${url.GET_SVG_DETAIL}?filename=${encodeURIComponent(window.btoa(unescape(encodeURIComponent(`${this.state.currentPjId}/svg/${filename.substring(0, filename.lastIndexOf('.'))}`))))}`,
      resultType: 'json',
      errorThrow: true,
    }), new Promise((resolve) => {
      setTimeout(() => {
        resolve('ok');
      }, 200);
    })]).then((results) => {
      if (results[0].ok && results[0].body !== '') {
        const svgObj = domToReact(htmlDomParser(results[0].body).map((svgItem) => {
          if (svgItem.name === 'svg') {
            svgItem.attribs.id = 'svg-panel';
            return {
              ...svgItem,
              children: svgItem.children.map((device, index) => this.formatDevice(device, index)),
            };
          }
          return svgItem;
        }));
        this.setState({
          svgObj,
          loading: false,
        });
      } else {
        throw new Error(results[0].body);
      }
      if (results[1].ok && results[1].body.code === 0) {
        this.setState({
          svgConfig: results[1].body.body,
        }, () => {
          this.getDeviceData();
          this.getBackgroundColor();
        });
      } else {
        throw new Error(results[1].body);
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      this.setState({
        loading: false,
      });
      this.props.setMessage({
        status: true,
        text: '获取SVG时出错了,即将返回首页',
      });
      setTimeout(() => {
        this.props.history.push('/');
      }, 4000);
    });
  };

  refreshConfig = () => {
    const { filename } = this.state;
    this.setState({
      loading: false,
    }, () => {
      this.api.sendRequest({
        url: `${url.GET_SVG_DETAIL}?filename=${encodeURIComponent(window.btoa(unescape(encodeURIComponent(`${this.state.currentPjId}/svg/${filename.substring(0, filename.lastIndexOf('.'))}`))))}`,
        resultType: 'json',
        errorThrow: true,
      }).then((result) => {
        if (result.ok && result.body.code === 0) {
          this.props.setMessage({
            status: true,
            text: '设置成功',
            theme: MessageStyles.theme.info,
            position: MessageStyles.position.top,
          });
          this.setState({
            svgConfig: result.body.body,
            loading: false,
          }, () => {
            this.getDeviceData();
            this.getBackgroundColor();
          });
        } else {
          this.props.setMessage({
            status: true,
            text: '获取SVG配置时出错了',
          });
        }
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        this.props.setMessage({
          status: true,
          text: '出错了',
        });
      });
    });
  };

  getDeviceData = () => {
    const systemSet = this.state.svgConfig.filter((svg) => svg.id === 'system-set' && svg.type === 'system');
    if (!this.state.ifEdit) {
      this.domRender.renderSiblingsClick(this.state.svgConfig.filter((svg) => svg.redirectTarget !== undefined));
    }
    if (this.dataInterval !== undefined) {
      clearInterval(this.dataInterval);
    }
    this.getDetail();
    this.dataInterval = setInterval(this.getDetail, systemSet.length === 0 ? 5000 : Tools.emptyNumber(systemSet[0].interval, 5000));
  };

  getDetail = () => {
    const config = this.state.svgConfig.filter((svg) => svg.type !== undefined && svg.type !== 'system' && svg.id !== '' && svg.detail !== undefined).map((svg) => ({
      devId: svg.detail.deviceId,
      category: svg.detail.category,
      detailId: svg.detail.detailId,
      id: svg.id,
    }));
    this.api.sendRequest({
      url: url.REALTIME,
      method: 'post',
      body: JSON.stringify(config),
      type: 1,
      errorThrow: true,
    }).then((result) => {
      const now = new Date();
      if (result.ok && result.body.resCode === 0) {
        this.domRender.render(result.body.resBody.map((res) => ({
          ...this.state.svgConfig.filter((svg) => svg.id === res.id)[0],
          ...res,
        })).map(res => {
          if (res.time !== undefined && /^\d+$/.test(res.time) && now - res.time > (31 * 60 * 1000)) {
            res.value = '';
          }
          return res;
        }));
      } else {
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
  };

  formatDevice = (device, index) => {
    if (device.children !== undefined && device.children.length > 0 && device.children[0].type !== 'text') {
      return {
        ...device,
        children: device.children.map((child, ind) => this.formatDevice(child, ind)),
      };
    }
    if (device.attribs === undefined) {
      device.attribs = {};
    }
    if (device.attribs.id === undefined) {
      device.attribs.id = device.parent.attribs.id + '_' + index;
    }
    device.attribs.onClick = this.deviceClick;
    device.attribs.onMouseOver = this.deviceOver;
    device.attribs.onMouseOut = this.deviceOut;
    if (device.attribs.style === undefined) {
      device.attribs.style = '';
    }
    device.attribs.style = 'cursor:pointer;' + device.attribs.style;
    return device;
  };

  deviceClick = (event) => {
    const id = event.target.id !== undefined && event.target.id !== ''
      ? event.target.id
      : (event.target.parentNode.id !== undefined && event.target.parentNode.id !== ''
        ? (event.target.parentNode.id + '@' + Array.prototype.slice.apply(event.target.parentNode.children).indexOf(event.target))
        : '');
    if (this.state.ifEdit) {
      event.preventDefault();
      event.stopPropagation();
      // this.fallbackCheckStyle();
      this.domRender.removeCurrent();
      this.domRender.setCurrent(event.target);
      event.nativeEvent.stopImmediatePropagation();
      const config = [...this.state.svgConfig];
      this.props.switchDeviceSet(false);
      this.props.switchDeviceControl(false);
      this.props.switchContextMenu(false);
      this.props.switchDeviceMenu(false);
      if (id !== undefined && id !== '') {
        this.props.switchDrawer(DrawerStyles.state.grow);
        this.props.setCurrentDevice(event.target);
        this.props.setCurrentDetails({
          id,
          type: event.target.tagName,
          details: Tools.first(config.filter((svg) => svg.id === id || svg.id === id.replace('@', ''))),
        });
      } else {
        this.props.setMessage({
          status: true,
          text: '节点或父节点未设置ID',
        });
      }
    } else {
      const detail = Tools.first(this.state.svgConfig.filter((svg) => svg.id === id));
      if (detail.redirectTarget !== undefined && detail.redirectTarget !== '') {
        this.props.history.push(`/${window.btoa(this.state.currentPjId)}/${encodeURIComponent(window.btoa(unescape(encodeURIComponent(detail.redirectTarget))))}/${Tools.addSalt(window.btoa('0'))}`);
      }
    }
  };

  deviceOver = (event) => {
    if (!this.state.ifEdit) {
      return;
    }
    if (this.overTasks.length > 0) {
      this.overTasks.forEach((task) => {
        task.cancel();
      });
    }
    event.stopPropagation();
    if (event.target.tagName === 'line') {
      event.target.style.setProperty('stroke-width', Tools.emptyNumber(event.target.style.getPropertyValue('stroke-width'), 1) + 2);
    } else if (event.target.tagName === 'circle') {
      event.target.setAttribute('r', Tools.emptyNumber(event.target.getAttribute('r')) + 2);
    } else {
      event.target.style.fill = this.calculate(event.target.style.fill, 0);
      event.target.style.stroke = this.calculate(event.target.style.stroke, 0);
    }
    this.showDetailTip(event);
    const id = event.target.id !== undefined && event.target.id !== ''
      ? event.target.id
      : (event.target.parentNode.id !== undefined && event.target.parentNode.id !== ''
        ? (event.target.parentNode.id + '@' + Array.prototype.slice.apply(event.target.parentNode.children).indexOf(event.target))
        : '');
    event.target.addEventListener('contextmenu', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (this.state.ifEdit) {
        let clickX = e.clientX;
        let clickY = e.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const menuWidth = Tools.remRadio(100);
        const menuHeight = Tools.remRadio(80);
        if (clickX + menuWidth > screenW) {
          clickX -= menuWidth;
        }
        if (clickY + menuHeight > screenH) {
          clickY -= menuHeight;
        }
        this.props.switchContextMenu(false);
        this.props.switchDeviceMenu(true);
        if (id !== undefined && id !== '') {
          this.props.setCurrentDetails({
            id,
            details: Tools.first(this.state.svgConfig.filter((svg) => svg.id === id || svg.id === id.replace('@', ''))),
          });
        } else {
          this.props.setMessage({
            status: true,
            text: '节点或父节点未设置ID',
          });
        }
        this.setState({
          deviceMenuX: clickX,
          deviceMenuY: clickY,
        });
      }
    }, {
      once: true,
    });
  };

  getDomId = (dom) => {
    if (dom.id !== undefined && dom.id !== '') {
      return dom.id;
    }
    if (dom.parentNode !== undefined && dom.parentNode.children !== undefined && dom.parentNode.children.length > 0) {
      return dom.parentNode.id + '@' + Array.prototype.slice.apply(dom.parentNode.children).indexOf(dom);
    }
    return '';
  };

  showDetailTip = (event) => {
    if (this.props.showPrepared && event.target.classList.contains('flickerAnimate')) {
      const domId = this.getDomId(event.target);
      const targetConfig = this.state.svgConfig.filter((config) => config.id === domId || config.id === domId.replace('@', ''));
      const { positionX, positionY } = {
        positionX: event.clientX > (window.innerWidth - 200) ? {
          right: `${window.innerWidth - event.clientX + 10}px`,
        } : {
          left: `${event.clientX + 10}px`,
        },
        positionY: event.clientY > (window.innerHeight - 50) ? {
          bottom: `${window.innerHeight - event.clientY}px`,
        } : {
          top: `${event.clientY}px`,
        },
      };
      if (targetConfig.length > 0) {
        const task = Tools.debounce(() => {
          this.setState({
            detailTip: {
              show: true,
              position: {
                ...positionX,
                ...positionY,
              },
              content: (targetConfig[0].redirectTarget ? `跳转: ${Tools.formatFilename(targetConfig[0].redirectTarget)[0]}; ` : '')
                + (targetConfig[0].control ? `遥控: ${targetConfig[0].control.detail.deviceId} - ${targetConfig[0].control.detail.category} - ${targetConfig[0].control.detail.detailId}; ` : '')
                + (targetConfig[0].detail ? `${targetConfig[0].detail.deviceId} - ${targetConfig[0].detail.category} - ${targetConfig[0].detail.detailId}` : ''),
            },
          });
        }, 300);
        this.overTasks.push(task);
        task();
      }
    }
  };

  deviceOut = (event) => {
    if (!this.state.ifEdit) {
      return;
    }
    event.stopPropagation();
    if (event.target.tagName === 'line') {
      event.target.style.setProperty('stroke-width', Tools.emptyNumber(event.target.style.getPropertyValue('stroke-width'), 1) - 2);
    } else if (event.target.tagName === 'circle') {
      event.target.setAttribute('r', Tools.emptyNumber(event.target.getAttribute('r')) - 2);
    } else {
      event.target.style.fill = this.calculate(event.target.style.fill, 1);
      event.target.style.stroke = this.calculate(event.target.style.stroke, 1);
    }
    if (this.overTasks.length > 0) {
      this.overTasks.forEach((task) => {
        task.cancel();
      });
    }
    this.setState({
      detailTip: {
        show: false,
        position: {
          left: 0,
          top: 0,
        },
        content: '',
      },
    });
  };

  calculate = (target, operation) => {
    if (target !== undefined && target !== 'none' && target !== '' && regexp.test(target)) {
      const fillColors = target.match(regexp)[0].replace(/\s*/g, '').split(',').map((color, index) => (operation === 0 ? (color * grayColor[index]) : (color / grayColor[index])).toFixed(3)).join(',');
      return target.replace(regexp, fillColors);
    }
    return target;
  };

  hideDrawer = (event) => {
    if (!this.props.drawer.nail) {
      event.preventDefault();
      event.stopPropagation();
      // this.fallbackCheckStyle();
      this.domRender.removeCurrent();
      this.props.switchDrawer(DrawerStyles.state.shrink);
      this.props.setCurrentDevice({});
      this.props.setCurrentDetails({});
    }
    this.props.switchContextMenu(false);
    this.props.switchDeviceMenu(false);
    this.props.switchDeviceSet(false);
    this.props.switchDeviceControl(false);
  };

  contextMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.state.ifEdit && this.state.svgObj !== undefined && !this.state.showSystemSet) {
      let clickX = event.clientX;
      let clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const menuWidth = Tools.remRadio(100);
      const menuHeight = Tools.remRadio(160);
      if (clickX + menuWidth > screenW) {
        clickX -= menuWidth;
      }
      if (clickY + menuHeight > screenH) {
        clickY -= menuHeight;
      }
      this.props.switchDeviceMenu(false);
      this.props.switchDeviceSet(false);
      this.props.switchDeviceControl(false);
      this.props.switchContextMenu(true);
      this.setState({
        contextMenuX: clickX,
        contextMenuY: clickY,
      });
    }
  };

  resetInterval = () => {
    clearInterval(this.dataInterval);
    this.refreshConfig();
  };

  getBackgroundColor = () => {
    if (this.state.svgConfig !== undefined) {
      const systemSet = this.state.svgConfig.filter((svg) => svg.id === 'system-set' && svg.type === 'system');
      this.props.setBackgroundColor(systemSet.length === 0 ? Tools.defaultBackColor() : Tools.emptyObj(systemSet[0].backgroundColor, Tools.defaultBackColor()));
    }
  };

  svgPicker = (svg) => {
    this.props.history.push(`/${window.btoa(this.state.currentPjId)}/${encodeURIComponent(window.btoa(unescape(encodeURIComponent(svg))))}/${Tools.addSalt(window.btoa(this.state.ifEdit ? '1' : '0'))}`);
  };

  switchExhibition = () => {
    this.props.history.push(`/${window.btoa(this.state.currentPjId)}/${this.props.match.params.filename}/${Tools.addSalt(window.btoa('0'))}`);
  };

  cancelDetail = () => {
    if (this.props.currentDetails.details !== undefined && Object.prototype.hasOwnProperty.call(this.props.currentDetails.details, 'id')) {
      this.setState({
        loading: true,
      }, () => {
        Promise.all([this.api.sendRequest({
          url: `${url.CANCEL_DETAIL}?projectId=${this.state.currentPjId}&configId=${this.props.currentDetails.details.id}&filename=${encodeURIComponent(window.btoa(unescape(encodeURIComponent(this.state.filename.replace(/\.svg/g, '')))))}`,
          resultType: 'json',
          method: 'delete',
          errorThrow: true,
        }), new Promise((resolve) => {
          setTimeout(() => {
            resolve('ok');
          }, 1000);
        })]).then((res) => {
          if (res[0].ok && res[0].body.code === 0) {
            this.resetInterval();
          }
          if (this.domRender.getDom(this.props.currentDetails.details.id)) {
            this.domRender.getDom(this.props.currentDetails.details.id).innerHTML = '--';
          }
          this.setState({
            loading: false,
          });
          this.props.switchDeviceMenu(false);
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          this.setState({
            loading: false,
          });
        });
      });
    }
  };

  exportPackage = () => {
    this.setState({
      loading: true,
    }, () => {
      Promise.all([this.api.sendRequest({
        url: `${url.EXPORT_PACKAGE}?projectId=${this.state.currentPjId}&filename=${encodeURIComponent(window.btoa(unescape(encodeURIComponent(this.state.filename.replace(/\.svg/g, '')))))}`,
        resultType: 'blob',
        method: 'get',
        errorThrow: true,
      }), new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 1000);
      })]).then((res) => {
        this.setState({
          loading: false,
        }, () => {
          this.props.switchContextMenu(false);
          const a = document.createElement('a');
          const downloadUrl = window.URL.createObjectURL(res[0]);
          a.href = downloadUrl;
          a.download = '1-package.zip';
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
        });
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.setState({
          loading: false,
        });
      });
    });
  };

  render() {
    const {svgObj} = this.state;
    return <div className={styles.main} onClick={(event) => this.hideDrawer(event)} style={{
      backgroundColor: this.props.backgroundColor,
    }}>
      <div className={styles.svgPanel} style={{
        width: this.props.drawer.status === 'grow' ? 'calc(100% - 6.4rem)' : '100%',
        transform: this.props.drawer.status === 'grow' ? 'perspective(50rem) rotate3d(0, 1, 0, -20deg)' : 'perspective(0rem) rotate3d(0, 0, 0, 0deg)',
      }} onContextMenu={(event) => this.contextMenu(event)}>
        {
          this.state.svgObj !== undefined && <SvgAdaptor svgObj={this.state.svgObj}/>
        }
      </div>
      {
        this.state.loading && <CircleLoading theme={'light'}/>
      }
      <Drawer filename={this.state.filename} complete={this.resetInterval} projectId={this.state.currentPjId}/>
      {
        this.props.contextMenu.status && (
          <ContextMenu left={this.state.contextMenuX} history={this.props.history} top={this.state.contextMenuY}
                       switchExhibition={this.switchExhibition} exportPackage={this.exportPackage}/>)
      }
      {
        this.props.deviceMenuStatus && (
          <DeviceMenu left={this.state.deviceMenuX} top={this.state.deviceMenuY} cancelDetail={this.cancelDetail}/>
        )
      }
      {
        this.props.deviceControlStatus && (
          <DeviceControl projectId={this.state.currentPjId} filename={this.state.filename}
                         complete={this.resetInterval}/>
        )
      }
      <SystemSet complete={this.resetInterval} filename={this.state.filename}
                 show={this.props.systemSet.status} projectId={this.state.currentPjId}
                 config={this.state.svgConfig !== undefined ? Tools.first(this.state.svgConfig.filter((svg) => svg.id === 'system-set' && svg.type === 'system')) : {}}/>
      {
        // !this.state.ifEdit &&
        this.props.projectSvgList.length > 0 && (
          <div className={`${styles.svgListPanel} ${this.state.svgListStatus ? styles.active : styles.inactive}`}>
            <div className={styles.svgList}>
              <SvgList svgList={this.props.projectSvgList} onClick={this.svgPicker} currentSvg={this.state.filename}/>
            </div>
            <div className={styles.svgShrink} onClick={() => this.setState((prevState) => ({
              svgListStatus: !prevState.svgListStatus,
            }))}/>
          </div>
        )
      }
      {
        this.props.deviceSetStatus && (
          <DeviceSet filename={this.state.filename} projectId={this.state.currentPjId} complete={this.resetInterval}/>
        )
      }
      {
        this.state.detailTip.show && (
          <div className={styles.detailTip} style={{
            ...this.state.detailTip.position,
          }}>
            {
              this.state.detailTip.content
            }
          </div>
        )
      }
    </div>;
  }
}

SvgLoader.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  drawer: PropTypes.shape({
    status: PropTypes.oneOf(Object.entries(DrawerStyles.state).map((style) => style[1])).isRequired,
    nail: PropTypes.bool.isRequired,
  }).isRequired,
  contextMenu: PropTypes.shape({
    status: PropTypes.bool.isRequired,
  }).isRequired,
  switchDrawer: PropTypes.func.isRequired,
  switchContextMenu: PropTypes.func.isRequired,
  systemSet: PropTypes.shape({
    status: PropTypes.bool.isRequired,
  }).isRequired,
  setCurrentDevice: PropTypes.func.isRequired,
  currentDevice: PropTypes.object.isRequired,
  currentDetails: PropTypes.object.isRequired,
  setCurrentDetails: PropTypes.func.isRequired,
  showPrepared: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  getSvgList: PropTypes.func.isRequired,
  projectSvgList: PropTypes.array.isRequired,
  switchPrepared: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  setBackgroundColor: PropTypes.func.isRequired,
  switchDeviceMenu: PropTypes.func.isRequired,
  switchDeviceSet: PropTypes.func.isRequired,
  deviceMenuStatus: PropTypes.bool.isRequired,
  deviceSetStatus: PropTypes.bool.isRequired,
  switchDeviceControl: PropTypes.func.isRequired,
  deviceControlStatus: PropTypes.bool.isRequired,
};

export default withRouter(SvgLoader);
