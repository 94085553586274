import TYPES from './context-menu.types';

const activeContextMenuAction = (status) => (dispatch) => {
  dispatch({
    type: TYPES.ACTIVE_CONTEXT_MENU,
    data: status,
  });
};

const showPrepared = () => (dispatch) => {
  dispatch({
    type: TYPES.SHOW_PREPARED,
  });
};

export default {
  activeContextMenuAction,
  showPrepared,
};
