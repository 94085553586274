import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ContextMenu from '../../components/context-menu/context-menu';

import systemSetActions from '../../redux/system-set/system-set.action';
import contextMenuActions from '../../redux/context-menu/context-menu.action';

const mapStateToProps = (state) => ({
  prepared: state.contextMenu.showPrepared,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    switchSystemSet: systemSetActions.activeSystemSetAction,
    showPrepared: contextMenuActions.showPrepared,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenu);
