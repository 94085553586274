import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tools from '../../utils/tools';

import styles from './combo-box.less';

class ComboBox extends Component {
  constructor(props) {
    super(props);
    const filterData = props.data.filter((data) => data.key === props.value);
    this.state = {
      slide: false,
      value: props.value === undefined || filterData.length === 0 ? Tools.first(props.data).name : filterData[0].name,
    };
    props.getValue(props.value === undefined ? Tools.first(props.data).key : props.data.filter((data) => data.key === props.value)[0].key);
    this.canvas = React.createRef();
  }

  componentDidMount() {
    const that = this;
    this.drawCanvas();
    window.addEventListener('resize', () => {
      clearTimeout(that.resizeTimeout);
      that.resizeTimeout = setTimeout(() => {
        that.drawCanvas();
      }, 200);
    }, false);
  }

  slide = () => {
    this.setState((prevState) => ({
      slide: !prevState.slide,
    }));
  };

  setValue = (detail) => {
    this.setState({
      value: detail.name,
    }, () => {
      this.props.getValue(detail.key);
    });
  };

  drawCanvas = () => {
    if (this.canvas.current !== null) {
      const canvas = this.canvas.current;
      canvas.width = Tools.remRadio(20);
      canvas.height = Tools.remRadio(12);
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = '#aaa';
      ctx.lineWidth = Tools.remRadio(3);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(Tools.remRadio(10), Tools.remRadio(10));
      ctx.lineTo(Tools.remRadio(20), 0);
      ctx.stroke();
    }
  };

  render() {
    return <div className={styles.main} style={
      {
        width: this.props.width,
        height: this.props.height,
      }
    } onClick={this.slide}>
      <span>
        {
          this.state.value
        }
      </span>
      <div
        className={`${styles.icon} ${this.state.slide !== undefined ? (this.state.slide ? styles.active : styles.inactive) : ''}`}>
        <canvas ref={this.canvas} width={'0.18rem'} height={'0.12rem'}/>
      </div>
      <div className={styles.panel} style={
        {
          height: this.state.slide ? '1.5rem' : '0',
          top: this.props.height,
        }
      }>
        <div className={styles.slider}>
          {
            this.props.data.map((detail) => (
              <div key={detail.key} className={styles.item} onClick={() => this.setValue(detail)}>{detail.name}</div>
            ))
          }
        </div>
      </div>
    </div>;
  }
}

ComboBox.propTypes = {
  data: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ComboBox.defaultProps = {
  width: '1.8rem',
  height: '0.32rem',
  data: [
    {
      key: '10',
      name: '遥信',
    },
    {
      key: '20',
      name: '遥测',
    },
    {
      key: '30',
      name: '遥脉',
    },
    {
      key: '40',
      name: '定值',
    },
    {
      key: '50',
      name: '历史',
    },
    {
      key: '60',
      name: '计算',
    },
    {
      key: '70',
      name: '遥控',
    },
    {
      key: '80',
      name: '遥调',
    },
    {
      key: '90',
      name: '混合',
    },
    {
      key: '100',
      name: '报警',
    },
    {
      key: '110',
      name: '事件',
    },
    {
      key: 'v',
      name: '虚拟',
    },
  ],
};

export default ComboBox;
