import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CircleLoading from '../../components/loading/circle/circle';
import LoadingStyles from '../../entity/loading';

class VisibleLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {type, status, theme} = this.props.loading;
    return status && type === LoadingStyles.type.circle && <CircleLoading theme={theme}/>;
  }
}

VisibleLoading.propTypes = {
  loading: PropTypes.shape({
    type: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    theme: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisibleLoading);
