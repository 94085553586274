import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '@daqo/color-picker';
import Api from '../../api/api';
import url from '../../api/url';

import styles from './system-set.less';
import LoadingStyles from '../../entity/loading';
import Button from '../button/button';
import Tools from '../../utils/tools';

class SystemSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tips: {
        show: false,
        text: '',
        theme: 'info',
      },
      config: {
        interval: 5000,
        backgroundColor: Tools.defaultBackColor(),
        ...props.config,
      },
      colorPicker: false,
      colorPickerFontSize: `${Tools.remRadio(14)}px`,
    };
    this.api = new Api();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config) {
      this.setState((ps) => ({
        config: {
          ...ps.config,
          ...this.props.config,
        },
      }));
    }
    if (prevProps.show !== this.props.show && this.props.show) {
      this.props.setLoading({
        status: false,
      });
      this.props.setMessage({
        status: false,
      });
      this.setState({
        config: {
          interval: 5000,
          backgroundColor: Tools.defaultBackColor(),
          ...this.props.config,
        },
        colorPicker: false,
      });
    }
  }

  componentDidMount() {
    this.resizeTask = [];
    window.addEventListener('resize', this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  windowResize = () => {
    const task = Tools.debounce(() => {
      if (this.state.colorPicker) {
        if (this.resizeTask.length > 0) {
          this.resizeTask.forEach((t) => t.cancel());
          this.resizeTask = [];
        }
        this.setState({
          colorPickerFontSize: `${Tools.remRadio(14)}px`,
        });
      }
    }, 300);
    this.resizeTask.push(task);
    task();
  };

  handleChange = (event) => {
    const { name, value } = {
      name: event.target.name,
      value: (event.target.type === 'checkbox' ? event.target.checked : event.target.value),
    };
    this.setState((prevState) => ({
      config: {
        ...prevState.config,
        [name]: value,
      },
    }));
  };

  saveConfig = () => {
    this.props.setLoading({
      status: true,
      theme: LoadingStyles.theme.dark,
    });
    const { filename } = this.props;
    const formdata = new FormData();
    formdata.append('projectId', this.props.projectId);
    formdata.append('filename', window.btoa(unescape(encodeURIComponent(filename.substring(0, filename.lastIndexOf('.'))))));
    formdata.append('detail', JSON.stringify({
      id: 'system-set',
      type: 'system',
      ...this.state.config,
    }));
    Promise.all([
      this.api.sendRequest({
        url: url.ADD_SVG_DETAIL,
        method: 'put',
        body: formdata,
        errorThrow: true,
      }),
      new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 1000);
      }),
    ]).then((results) => {
      this.props.setLoading({
        status: false,
      });
      if (results[0].ok && results[0].body.code === 0) {
        this.props.complete();
      } else {
        this.props.setMessage({
          status: '更新系统设置时出错了',
        });
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      this.props.setMessage({
        status: '出错了',
      });
    });
  };

  showColorPicker = (flag) => {
    this.setState({
      colorPicker: flag,
    });
  };

  getColor = (colors) => {
    this.props.setBackgroundColor(colors[0]);
    this.setState((prevState) => ({
      config: {
        ...prevState.config,
        backgroundColor: colors[0],
      },
    }));
  };

  cancelPickColor = () => {
    this.showColorPicker(false);
    this.setState({
      config: {
        interval: 5000,
        backgroundColor: Tools.defaultBackColor(),
        ...this.props.config,
      },
    }, () => {
      this.props.setBackgroundColor(this.state.config.backgroundColor);
    });
  };

  cancelSystemSet = () => {
    this.props.switchSystemSet(false);
    this.props.setBackgroundColor({
      backgroundColor: Tools.defaultBackColor(),
      ...this.props.config,
    }.backgroundColor);
  };

  render() {
    return <div className={styles.main} onClick={this.cancelSystemSet} style={{
      transform: `scale(${this.props.show ? 1 : 0})`,
    }}>
      <div className={styles.panel} onClick={(event) => event.stopPropagation()}>
        <div className={styles.item}>
          <div className={styles.desc}>请求间隔</div>
          <input type={'number'} className={styles.text} name={'interval'}
                 value={this.state.config.interval === undefined ? 5000 : this.state.config.interval}
                 placeholder={'数据请求时间间隔，单位: ms'} onChange={(event) => this.handleChange(event)}/>
        </div>
        <div className={styles.item}>
          <div className={styles.desc}>背景色</div>
          <div className={styles.picker}>
            <div className={styles.colorView} style={{
              backgroundColor: this.state.config.backgroundColor,
            }} onClick={() => this.showColorPicker(true)}/>
            <div className={styles.colorText}>
              {
                this.state.config.backgroundColor
              }
            </div>
          </div>
        </div>
        <div className={styles.submit} onClick={this.saveConfig}><span>提交</span></div>
      </div>
      {
        this.state.colorPicker && (
          <div className={styles.colorPanel} onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}>
            <div className={styles.colorPicker} onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}>
              <ColorPicker getColor={(colors) => this.getColor(colors)} cssModules={true}
                           color={this.state.config.backgroundColor} fontSize={`${Tools.remRadio(14)}px`}/>
              <div className={styles.btn}>
                <Button text={'确定'} onClick={() => this.showColorPicker(false)}/>
                <Button text={'取消'} onClick={() => this.cancelPickColor()}/>
              </div>
            </div>
          </div>
        )
      }
    </div>;
  }
}

SystemSet.propTypes = {
  switchSystemSet: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  filename: PropTypes.string.isRequired,
  complete: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setBackgroundColor: PropTypes.func.isRequired,
};

export default SystemSet;
