const url = {
  GET_PROJECTS: 'project/getProjects',
  GET_SVG: 'project/getProjectSvgs',
  UPLOAD_SVG: 'project/uploadPjSvg',
  ADD_SVG_DETAIL: 'project/addSvgDetail',
  GET_PROJECT_DEVICES: 'device/getDevicesByProject',
  GET_DETAILS: 'detail/getDetails',
  GET_TARGET_SVG: 'project/getTargetSvg',
  GET_SVG_DETAIL: 'project/getSvgDetail',
  REMOVE_SVG: 'project/removeSvg',
  REALTIME: 'realtime',
  CANCEL_DETAIL: 'project/removeDetail',
  EXPORT_PACKAGE: 'project/exportSvg',
};

export default url;
