import TYPES from './message-box.types';
import MessageStyles from '../../entity/message';

const initialState = {
  status: false,
  text: '出错了',
  theme: MessageStyles.theme.error,
  position: MessageStyles.position.top,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SHOW_MESSAGE:
      return {
        ...state,
        status: true,
        ...action.data,
      };
    case TYPES.HIDE_MESSAGE:
      return {
        ...state,
        status: false,
        ...action.data,
      };
    default:
      return state;
  }
};
