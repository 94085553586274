import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  applyToPoint,
  fromObject,
  inverse,
  scale,
  toSVG,
  transform,
  translate,
} from 'transformation-matrix';

import styles from './svg-adaptor.less';

class SvgAdaptor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgObj: [
        React.cloneElement(
          Array.isArray(props.svgObj) ? props.svgObj.filter((svg) => svg !== null && svg.type === 'svg')[0] : props.svgObj, {
            onWheel: this.onWheel,
            onMouseDown: this.onMouseDown,
            onMouseMove: this.onMouseMove,
            onMouseUp: this.onMouseUp,
          },
        ),
      ],
    };
    this.zoom = {
      a: 1,
      b: 0,
      c: 0,
      d: 1,
      e: 0,
      f: 0,
    };
    this.drag = false;
    this.postion = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.svgObj !== undefined && prevProps.svgObj !== this.props.svgObj) {
      this.setState({
        svgObj: [
          React.cloneElement(
            this.props.svgObj.filter((svg) => svg !== null && svg.type === 'svg')[0], {
              onWheel: this.onWheel,
              onMouseDown: this.onMouseDown,
              onMouseMove: this.onMouseMove,
              onMouseUp: this.onMouseUp,
            },
          ),
        ],
      });
    }
  }

  componentDidMount() {}

  onWheel = (event) => {
    const {mx, my} = this.getMousePosition(event, event.currentTarget);
    const delta = Math.max(-1, Math.min(1, event.deltaY));
    const scaleFactor = this.mapRange(delta, -1, 1, 1.06, 1 / 1.06);
    const SVGPoint = this.getSVGPoint(this.zoom, mx, my);
    this.zoom = this.zoomSVG(this.zoom, SVGPoint.x, SVGPoint.y, scaleFactor);
    event.currentTarget.setAttribute('transform', toSVG(this.zoom));
    event.currentTarget.setAttribute('transform-origin', '0px 0px');
  };

  zoomSVG = (value, SVGPointX, SVGPointY, scaleFactor) => transform(fromObject(value), translate(SVGPointX, SVGPointY), scale(scaleFactor, scaleFactor), translate(-SVGPointX, -SVGPointY))

  getSVGPoint = (value, viewerX, viewerY) => {
    const matrix = fromObject(value);
    const inverseMatrix = inverse(matrix);
    return applyToPoint(inverseMatrix, {x: viewerX, y: viewerY});
  };

  mapRange = (value, sourceStart, sourceEnd, targetStart, targetEnd) => targetStart + (targetEnd - targetStart) * (value - sourceStart) / (sourceEnd - sourceStart);

  getMousePosition = (event, dom) => {
    const {left, top} = dom.getBoundingClientRect();
    return {mx: event.clientX - Math.round(left), my: event.clientY - Math.round(top)};
  };

  onMouseMove = (event) => {
    if (!this.drag) return;
    const point = this.getMousePosition(event, event.currentTarget);
    this.zoom.e += (point.mx - this.postion.mx);
    this.zoom.f += (point.my - this.postion.my);
    event.currentTarget.setAttribute('transform', toSVG(this.zoom));
    event.currentTarget.setAttribute('transform-origin', '0px 0px');
  };

  onMouseDown = (event) => {
    this.drag = true;
    this.postion = this.getMousePosition(event, event.currentTarget);
  };

  onMouseUp = () => {
    this.drag = false;
    this.postion = {};
  };

  render() {
    return <div className={styles.main}>
      {
        this.state.svgObj ? this.state.svgObj : <div/>
      }
    </div>;
  }
}

SvgAdaptor.propTypes = {
  svgObj: PropTypes.array.isRequired,
};

export default SvgAdaptor;
