import TYPES from './loading.types';
import LoadingStyles from '../../entity/loading';

const defaultState = {
  status: false,
  theme: LoadingStyles.theme.light,
  type: LoadingStyles.type.circle,
};

const loadingAction = (state) => (dispatch) => {
  const currentState = {
    ...defaultState,
    ...state,
  };
  return new Promise((resolve) => {
    if (currentState.status) {
      dispatch({
        type: TYPES.LOADING_START,
        data: currentState,
      });
    } else {
      dispatch({
        type: TYPES.LOADING_END,
        data: currentState,
      });
    }
    resolve('ok');
  });
};

export default {
  loadingAction,
};
