import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './radio-button.less';

class RadioButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  switchDevice = (index) => {
    this.props.switchDevice(index);
  };

  render() {
    return <div className={styles.main}>
      {
        this.props.items.map((device, index) => (
          <div key={device.id} style={{
            width: `${100 / this.props.items.length}%`,
          }} className={`${styles.item} ${this.props.active === index ? styles.active : ''}`} onClick={() => this.switchDevice(index)}>
            {
              device.name
            }
          </div>
        ))
      }
    </div>;
  }
}

RadioButton.propTypes = {
  items: PropTypes.array,
  switchDevice: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
};

export default RadioButton;
