import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ShowMsg from '../../components/show-msg/show-msg';
import MessageStyles from '../../entity/message';

class VisibleMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.messageBox.status && <ShowMsg {...this.props.messageBox}/>;
  }
}

VisibleMessage.propTypes = {
  messageBox: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    position: PropTypes.oneOf(Object.entries(MessageStyles.position).map((style) => style[1])).isRequired,
    theme: PropTypes.oneOf(Object.entries(MessageStyles.theme).map((style) => style[1])).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  messageBox: state.messageBox,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisibleMessage);
