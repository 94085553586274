const MessageStyles = {
  position: {
    top: 'top',
    center: 'center',
  },
  theme: {
    error: 'error',
    info: 'info',
    warn: 'warn',
  },
};

export default MessageStyles;
