import TYPES from './svg-loader.types';
import LoadingStyles from '../../entity/loading';
import Tools from '../../utils/tools';

const initialState = {
  currentDetails: {},
  currentDevice: {},
  loading: {
    status: false,
    theme: LoadingStyles.theme.light,
    type: LoadingStyles.type.circle,
  },
  backgroundColor: Tools.defaultBackColor(),
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_CURRENT_DETAIL:
      return {
        ...state,
        currentDetails: action.data,
      };
    case TYPES.SET_CURRENT_DEVICE:
      return {
        ...state,
        currentDevice: action.data,
      };
    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case TYPES.SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.data,
      };
    default:
      return state;
  }
};
