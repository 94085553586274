const SET_CURRENT_DETAIL = 'set-current-detail';
const SET_CURRENT_DEVICE = 'set-current-device';
const SET_LOADING = 'set-loading';
const SET_BACKGROUND_COLOR = 'set-background-color';

export default {
  SET_CURRENT_DETAIL,
  SET_CURRENT_DEVICE,
  SET_LOADING,
  SET_BACKGROUND_COLOR,
};
