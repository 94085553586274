import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Drawer from '../../components/drawer/drawer';
import drawerActions from '../../redux/drawer/drawer.action';
import loadingActions from '../../redux/loading/loading.action';
import messageActions from '../../redux/message-box/message-box.action';

const mapStateToProps = (state) => ({
  state: state.drawer.status,
  nail: state.drawer.nail,
  details: state.svgLoader.currentDetails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setNail: drawerActions.switchNailAction,
    setLoading: loadingActions.loadingAction,
    setMessage: messageActions.messageAction,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Drawer);
