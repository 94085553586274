import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './show-msg.less';
import MessageStyles from '../../entity/message';

class ShowMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className={`${styles.main} ${styles[this.props.theme]}`} style={{
      top: this.props.position === 'center' ? 'calc(50% - 0.25rem)' : '1rem',
    }}>
      <div className={`${styles.text}`}>
        {
          this.props.text
        }
      </div>
    </div>;
  }
}

ShowMsg.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(Object.entries(MessageStyles.position).map((style) => style[1])).isRequired,
  theme: PropTypes.oneOf(Object.entries(MessageStyles.theme).map((style) => style[1])).isRequired,
};

export default ShowMsg;
