import loadingTypes from './loading/loading.types';
import svgPickerTypes from './svg-picker/svg-picker.types';
import messageTypes from './message-box/message-box.types';
import drawerTypes from './drawer/drawer.types';
import svgLoaderTypes from './svg-loader/svg-loader.types';
import systemSetTypes from './system-set/system-set.types';
import contextMenuTypes from './context-menu/context-menu.types';
import deviceMenuTypes from './device-menu/device-menu.types';
import deviceSetTypes from './device-set/device-set.types';
import deviceControlTypes from './device-control/device-control.types';

export default {
  ...loadingTypes,
  ...svgPickerTypes,
  ...messageTypes,
  ...drawerTypes,
  ...svgLoaderTypes,
  ...systemSetTypes,
  ...contextMenuTypes,
  ...deviceMenuTypes,
  ...deviceSetTypes,
  ...deviceControlTypes,
};
