import TYPES from './device-set.types';

const activeDeviceSet = (status) => (dispatch) => {
  dispatch({
    type: TYPES.ACTIVE_DEVICE_SET,
    data: status,
  });
};

export default {
  activeDeviceSet,
};
