import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import DeviceMenu from '../../components/device-menu/device-menu';
import deviceMenuAction from '../../redux/device-menu/device-menu.action';
import deviceSetActions from '../../redux/device-set/device-set.action';
import deviceControlActions from '../../redux/device-control/device-control.action';

const mapStateToProps = (state) => ({
  show: state.deviceMenu.show,
  currentDetails: state.svgLoader.currentDetails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    switchMenu: deviceMenuAction.activeDeviceMenu,
    switchDeviceSet: deviceSetActions.activeDeviceSet,
    switchDeviceControl: deviceControlActions.activeDeviceControl,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceMenu);
