import TYPES from './drawer.types';

const switchDrawerAction = (status) => (dispatch) => {
  dispatch({
    type: TYPES.SWITCH_DRAWER,
    data: status,
  });
};

const switchNailAction = () => (dispatch) => {
  dispatch({
    type: TYPES.SWITCH_NAIL,
  });
};

export default {
  switchDrawerAction,
  switchNailAction,
};
