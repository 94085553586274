import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from '../../redux/store/store-creator';
import './index.global.css';
import App from '../app/app';
import Tools from '../../utils/tools';
import * as serviceWorker from './serviceWorker';

let appName = '';
if (process.env.REACT_APP_OTTER === 'false') {
  appName = require('../../../package.json').homepage.replace(/http[s]?:\/\/(www)?[.\w\d-]+(:\d{2,5})?\/?/g, '');
}
// 禁止使用ctrl来控制浏览器缩放
// 禁止 + - 号控制放大缩小
document.addEventListener('keydown', (event) => {
  if ((event.ctrlKey === true || event.metaKey === true) && (event.key === '+' || event.key === '-')) {
    event.preventDefault();
  }
}, false);

// 禁止鼠标滚轮
document.addEventListener('DOMMouseScroll', (event) => {
  if (event.ctrlKey === true || event.metaKey) {
    event.preventDefault();
  }
}, false);
document.addEventListener('mousewheel', (event) => {
  if (event.ctrlKey === true || event.metaKey) {
    event.preventDefault();
  }
}, false);

if (Tools.ifMobile()) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

ReactDOM.render(<Provider store={store}>
  <BrowserRouter basename={appName}>
    <Switch>
      <Route component={App} path={'/'}/>
    </Switch>
  </BrowserRouter>
</Provider>, document.getElementById('root'));

serviceWorker.unregister();
