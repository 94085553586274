import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DetailConfig from '@daqo/react-detail-config-panel';
import DeviceType from '../../common/config/device-type.json';
import Tools from '../../utils/tools';
import RadioButton from '../radio-button/radio-button';

import DrawerStyles from '../../entity/drawer';

import styles from './drawer.less';
import Api from '../../api/api';
import url from '../../api/url';
import LoadingStyles from '../../entity/loading';
import MessageStyles from '../../entity/message';
import ComboBox from '../combo-box/combo-box';
import ValueType from '../../entity/value-type';

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIndex: 1,
      kvp: [],
      detail: {
        deviceId: '',
        category: '',
        detailId: '',
      },
      unit: '',
      valueType: 0,
      switch: {
        open: '',
        close: '',
      },
      sign: {
        normal: '',
        abnormal: '',
      },
      detailConfigSize: {
        width: Tools.remRadio(610),
        height: Tools.remRadio(400),
        fontSize: Tools.remRadio(14),
      },
    };
  }

  componentDidMount() {
    this.api = new Api();
    this.resizeTask = [];
    window.addEventListener('resize', this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  windowResize = () => {
    const task = Tools.debounce(() => {
      if (this.resizeTask.length > 0) {
        this.resizeTask.forEach((t) => t.cancel());
        this.resizeTask = [];
      }
      this.setState({
        detailConfigSize: {
          width: Tools.remRadio(610),
          height: Tools.remRadio(400),
          fontSize: Tools.remRadio(14),
        },
      });
    }, 300);
    this.resizeTask.push(task);
    task();
  };

  componentDidUpdate(prevProps) {
    if (this.props.details.id !== undefined && prevProps.details.id !== this.props.details.id && this.props.state === 'grow') {
      const getType = DeviceType.filter((type) => type.default.indexOf(this.props.details.type) > -1);
      const ind = this.props.details.details.type !== undefined ? Tools.first(DeviceType.filter((type) => type.desc === this.props.details.details.type), {
        id: 1,
      }).id : (getType.length > 0 ? getType[0].id : 1);
      this.setState({
        formIndex: ind,
        kvp: Tools.emptyObj(this.props.details.details.kvp, []),
        detail: Tools.emptyObj(this.props.details.details.detail, {
          deviceId: '',
          category: '',
          detailId: '',
        }),
        unit: Tools.emptyObj(this.props.details.details.unit, ''),
        switch: Tools.emptyObj(this.props.details.details.switchDetail, {
          open: '',
          close: '',
        }),
        sign: Tools.emptyObj(this.props.details.details.sign, {
          normal: '',
          abnormal: '',
        }),
      }, () => {
        // this.detailSet.reset();
      });
    }
  }

  switchDevice = (index) => {
    if (this.state.formIndex !== index) {
      this.setState({
        formIndex: index,
      });
    }
  };

  addJson = () => {
    this.setState((prevState) => ({
      kvp: prevState.kvp.concat([
        {
          key: '',
          value: '',
        },
      ]),
    }));
  };

  handleChange = (event, index, type) => {
    const { name, value } = {
      name: event.target !== undefined ? event.target.name : '',
      value: event.target !== undefined ? (event.target.type === 'checkbox' ? event.target.checked : event.target.value) : event,
    };
    this.setState((prevState) => {
      const mapping = {
        kvp: () => ({
          kvp: prevState.kvp.map((e, i) => {
            if (i === index) {
              e[name] = value;
            }
            return e;
          }),
        }),
        detail: () => ({
          detail: {
            ...prevState.detail,
            [name]: value,
          },
        }),
        unit: () => ({
          unit: value,
        }),
        valueType: () => ({
          valueType: value,
        }),
        switch: () => ({
          switch: {
            ...prevState.switch,
            [name]: value,
          },
        }),
        sign: () => ({
          sign: {
            ...prevState.sign,
            [name]: value,
          },
        }),
      };
      return mapping[type]();
    });
  };

  removeJson = (index) => {
    if (this.state.kvp.length > 1) {
      this.setState((prevState) => ({
        kvp: prevState.kvp.filter((map, ind) => ind !== index),
      }));
    }
  };

  judgeEmpty = (detail) => Tools.emptyObj(detail.category, '') !== '' && Tools.emptyObj(detail.detailId, '') && Tools.emptyObj(detail.deviceId, '');

  submit = () => {
    if (this.judgeEmpty(this.state.detail)) {
      this.props.setLoading({
        status: true,
        theme: LoadingStyles.theme.light,
      });
      const result = {
        type: DeviceType[this.state.formIndex].desc,
        detail: this.state.detail,
      };
      const mapping = {
        switch: () => ({
          switchDetail: this.state.switch,
        }),
        value: () => ({
          unit: this.state.unit,
          valueType: this.state.valueType,
        }),
        kvp: () => ({
          kvp: this.state.kvp,
        }),
        sign: () => ({
          sign: this.state.sign,
        }),
      };
      const { filename } = this.props;
      const formdata = new FormData();
      formdata.append('projectId', this.props.projectId);
      formdata.append('filename', window.btoa(unescape(encodeURIComponent(filename.substring(0, filename.lastIndexOf('.'))))));
      formdata.append('detail', JSON.stringify({
        id: this.props.details.id,
        ...result,
        ...mapping[result.type](),
      }));
      Promise.all([this.api.sendRequest({
        url: url.ADD_SVG_DETAIL,
        method: 'put',
        body: formdata,
        errorThrow: true,
      }), new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 1000);
      })]).then((results) => {
        const res = results[0];
        this.props.setLoading({
          status: false,
        });
        if (res.ok && res.body.code === 0) {
          this.props.complete();
        } else {
          this.props.setMessage({
            status: true,
            text: '更新数据点时出错了',
          });
        }
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.props.setMessage({
          status: true,
          text: '出错了',
        });
      });
    } else {
      this.props.setMessage({
        status: true,
        theme: MessageStyles.theme.warn,
        text: '未配置数据点',
      });
    }
  };

  setDetails = (detail) => {
    const kvp = this.formatFVP(detail.strDescinfo);
    this.setState(() => ({
      detail: {
        deviceId: detail.deviceInfo.strKey,
        category: detail.strDetailCategory,
        detailId: detail.strKey,
      },
      unit: Tools.emptyObj(detail.strDim, ''),
      ...detail,
      kvp,
    }));
  };

  formatFVP = (json) => {
    if (Tools.isJson(json)) {
      return Object.entries(JSON.parse(json)).map((detail) => ({
        key: detail[0],
        value: detail[1],
      }));
    }
    return [];
  };

  detailConfig = (child) => {
    this.detailSet = child;
  };

  render() {
    return <div className={styles.main} style={{
      width: this.props.state === 'grow' ? '6.4rem' : '0rem',
    }} onClick={(event) => event.stopPropagation()} onContextMenu={(event) => event.preventDefault()}>
      <div className={styles.panel} style={{
        transform: `translateX(${this.props.state === 'grow' ? '0rem' : '6.4rem'})`,
      }}>
        <div className={styles.title} data-value={'设备类型'}/>
        <RadioButton items={DeviceType} switchDevice={this.switchDevice} active={this.state.formIndex}/>
        <div className={styles.title} data-value={'数据配置'}/>
        <div className={styles.checkDetail}>
          <DetailConfig onCheck={this.setDetails} mounted={this.detailConfig} projectId={this.props.projectId}
                        theme={'light'} {...this.state.detailConfigSize} cssModules={true} appType={'ORIGIN'}/>
        </div>
        <div className={styles.detailSet}>
          <div className={styles.formItem}>
            <div className={styles.desc}>设备ID</div>
            <input type={'text'} className={styles.item} name={'deviceId'} placeholder={'设备GUID'}
                   value={this.state.detail.deviceId}
                   onChange={(event) => this.handleChange(event, '', 'detail')}/>
          </div>
          <div className={styles.formItem}>
            <div className={styles.desc}>数据类型</div>
            <input type={'text'} className={styles.item} name={'category'} placeholder={'数据类型'}
                   value={this.state.detail.category}
                   onChange={(event) => this.handleChange(event, '', 'detail')}/>
          </div>
          <div className={styles.formItem}>
            <div className={styles.desc}>数据ID</div>
            <input type={'text'} className={styles.item} name={'detailId'} placeholder={'数据ID'}
                   value={this.state.detail.detailId}
                   onChange={(event) => this.handleChange(event, '', 'detail')}/>
          </div>
        </div>
        <div className={styles.uncertain} style={{
          transform: `translateX(${-this.state.formIndex * 100}%)`,
        }}>
          <div className={styles.formType} style={{
            visibility: this.state.formIndex === 0 ? 'visible' : 'hidden',
          }}>
            <div className={styles.formItem}>
              <div className={styles.desc}>开</div>
              <input type={'text'} className={styles.item} name={'open'} placeholder={'请输入开关打开判定值'}
                     value={this.state.switch.open}
                     onChange={(event) => this.handleChange(event, '', 'switch')}/>
            </div>
            <div className={styles.formItem}>
              <div className={styles.desc}>合</div>
              <input type={'text'} className={styles.item} name={'close'} placeholder={'请输入开关关合判定值'}
                     value={this.state.switch.close}
                     onChange={(event) => this.handleChange(event, '', 'switch')}/>
            </div>
          </div>
          <div className={styles.formType} style={{
            visibility: this.state.formIndex === 1 ? 'visible' : 'hidden',
          }}>
            <div className={styles.formItem}>
              <div className={styles.desc}>量纲</div>
              <input type={'text'} className={styles.item} placeholder={'请输入数据量纲'} value={this.state.unit}
                     onChange={(event) => this.handleChange(event, '', 'unit')}/>
            </div>
            <div className={styles.formItem}>
              <div className={styles.desc}>类型</div>
              <div className={styles.item} style={{
                border: 0,
                justifyContent: 'flex-start',
                padding: 0,
              }}>
                <ComboBox data={ValueType} value={ValueType.filter((type) => type.key === this.state.valueType)[0].key}
                          getValue={(key) => this.handleChange(key, '', 'valueType')}/>
              </div>
            </div>
          </div>
          <div className={styles.formType} style={{
            visibility: this.state.formIndex === 2 ? 'visible' : 'hidden',
          }}>
            <div className={styles.title} data-value={'键值对'}/>
            <div className={styles.jsonBox}>
              {
                this.state.kvp.map((map, index) => (
                  <div className={styles.jsonItem} key={index}>
                    <input type={'text'} className={styles.item} placeholder={'请输入键'} value={map.key} name={'key'}
                           onChange={(event) => this.handleChange(event, index, 'kvp')}/>
                    <input type={'text'} className={styles.item} placeholder={'请输入值'} value={map.value} name={'value'}
                           onChange={(event) => this.handleChange(event, index, 'kvp')}/>
                    <div className={`${styles.delete} ${this.state.kvp.length === 1 ? styles.gray : ''}`}
                         onClick={() => this.removeJson(index)}/>
                  </div>
                ))
              }
            </div>
            <div className={styles.addJson} onClick={this.addJson}/>
          </div>
          <div className={styles.formType} style={{
            visibility: this.state.formIndex === 3 ? 'visible' : 'hidden',
          }}>
            <div className={styles.formItem}>
              <div className={styles.desc}>描述</div>
              <div className={styles.item} style={{
                border: '0',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                height: '0.32rem',
                lineHeight: '0.32rem',
              }} title={this.state.strDescinfo}>{
                this.state.strDescinfo
              }</div>
            </div>
            <div className={styles.formItem}>
              <div className={styles.desc}>正常</div>
              <input type={'text'} className={styles.item} name={'normal'} placeholder={'请输入正常判定值'}
                     value={this.state.sign.normal}
                     onChange={(event) => this.handleChange(event, '', 'sign')}/>
            </div>
            <div className={styles.formItem}>
              <div className={styles.desc}>异常</div>
              <input type={'text'} className={styles.item} name={'abnormal'} placeholder={'请输入异常判定值'}
                     value={this.state.sign.abnormal}
                     onChange={(event) => this.handleChange(event, '', 'sign')}/>
            </div>
          </div>
        </div>
        <div className={styles.submit} onClick={this.submit}/>
      </div>
      <div className={`${styles.nail} ${this.props.nail ? styles.check : styles.nail_uncheck}`}
           onClick={this.props.setNail} title={this.props.nail ? '已固定' : '已取消固定'}/>
    </div>;
  }
}

Drawer.propTypes = {
  state: PropTypes.oneOf(Object.entries(DrawerStyles.state).map((style) => style[1])),
  details: PropTypes.object,
  filename: PropTypes.string.isRequired,
  complete: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  nail: PropTypes.bool.isRequired,
  setNail: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};

Drawer.defaultProps = {
  state: DrawerStyles.state.shrink,
  details: {
    details: {},
  },
};

export default Drawer;
