import TYPES from './svg-picker.types';

const initialState = {
  projectList: [],
  svgList: [],
  currentProject: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_PROJECTS:
      return {
        ...state,
        ...action.data,
      };
    case TYPES.GET_SVG:
      return {
        ...state,
        ...action.data,
      };
    case TYPES.PICK_PROJECT:
      return {
        ...state,
        ...action.data,
      };
    case TYPES.PICK_SVG:
      return {
        ...state,
        currentSvg: action.data,
      };
    default:
      return state;
  }
};
