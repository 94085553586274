const GET_PROJECTS = 'get-projects';
const GET_SVG = 'get-svg';
const PICK_PROJECT = 'pick-project';
const PICK_SVG = 'pick-svg';

export default {
  GET_PROJECTS,
  GET_SVG,
  PICK_PROJECT,
  PICK_SVG,
};
