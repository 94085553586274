import deviceControlTypes from './device-control.types';

const initialState = {
  show: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case deviceControlTypes.ACTIVE_DEVICE_CONTROL:
      return {
        ...state,
        show: action.data,
      };
    default:
      return state;
  }
};
