import Tools from '../../utils/tools';
// import DynamicUnit from '../dynamic-unit/dynamic-unit';

class DomRender {
  render = (array) => {
    this.renderSwitch(array.filter((detail) => detail.type === 'switch'));
    this.renderValue(array.filter((detail) => detail.type === 'value'));
    this.renderKVP(array.filter((detail) => detail.type === 'kvp'));
    this.renderSign(array.filter((detail) => detail.type === 'sign'));
  };

  renderSwitch = (array) => {
    array.forEach((detail) => {
      const dom = this.getDom(detail.id);
      if (dom !== undefined && dom !== null && dom.x1 !== undefined && dom.x2 !== undefined && dom.y1 !== undefined && dom.y2 !== undefined) {
        const x1Val = dom.x1.baseVal;
        const x2Val = dom.x2.baseVal;
        const y1Val = dom.y1.baseVal;
        const y2Val = dom.y2.baseVal;
        dom.style.setProperty('stroke', (this.judgeSwitch(detail.switchDetail.close, detail.value)
          ? 'rgb(241, 72, 61)'
          : (this.judgeSwitch(detail.switchDetail.open, detail.value)
            ? 'rgb(0, 255, 9)'
            : 'rgb(191,190,190)')));
        // Array.prototype.slice.apply(dom.parentNode.children).forEach((child) => child.style.setProperty('stroke', (detail.switchDetail.open === detail.value ? 'rgb(0, 255, 9)' : 'rgb(241, 72, 61)')));
        const mapping = {
          0: [x1Val, x2Val, y1Val, y2Val],
          1: [y1Val, y2Val, x2Val, x1Val],
        };
        const func = (attr) => {
          if (attr[0].value > attr[1].value) {
            attr[2].newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_NUMBER, attr[3].value - (this.judgeSwitch(detail.switchDetail.close, detail.value) ? 0 : 9.25));
          } else {
            attr[3].newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_NUMBER, attr[2].value - (this.judgeSwitch(detail.switchDetail.close, detail.value) ? 0 : 9.25));
          }
        };
        func(mapping[Math.abs(x1Val.value - x2Val.value) > Math.abs(y1Val.value - y2Val.value) ? 0 : 1]);
      }
    });
  };

  judgeSwitch = (config, value) => (config + '') === (value + '') || config.replace(/\s/g, '').split(',').filter((item) => (item + '') === (value + '')).length > 0;

  renderKVP = (array) => {
    array.forEach((detail) => {
      const dom = this.getDom(detail.id);
      if (dom !== undefined && dom !== null) {
        const kvp = Tools.first(detail.kvp.filter((pair) => (pair.key + '') === (detail.value + '')), {
          value: Tools.emptyObj(detail.value, '--'),
        });
        dom.innerHTML = Tools.getValue(kvp.value);
      }
    });
  };

  renderValue = (array) => {
    array.forEach((detail) => {
      const dom = this.getDom(detail.id);
      if (dom !== undefined && dom !== null) {
        dom.innerHTML = detail.valueType === 0 ? Tools.getValue(Tools.fixNumber(detail.value)) + Tools.emptyObj(detail.unit, '') : Tools.dateFormat(new Date(parseFloat(detail.time)), 'yyyy-MM-dd HH:mm:ss');
      }
    });
  };

  renderSign = (array) => {
    array.forEach((detail) => {
      const dom = this.getDom(detail.id);
      if (dom !== undefined && dom !== null) {
        const mapping = {
          ...detail.sign.normal.replace(/\s/g, '').split(',').map((n) => ({
            [n]: 'rgb(23, 255, 0)',
          })).reduce((i1, i2) => ({
            ...i1,
            ...i2,
          }), {}),
          ...detail.sign.abnormal.replace(/\s/g, '').split(',').map((n) => ({
            [n]: 'rgb(241, 72, 61)',
          })).reduce((i1, i2) => ({
            ...i1,
            ...i2,
          }), {}),
        };
        dom.style.setProperty('fill', Tools.emptyObj(mapping[detail.value], 'rgb(191,190,190)'));
      }
    });
  };

  showPreparedDom = (array, flag) => {
    array.forEach((detail) => {
      const dom = this.getDom(detail.id);
      if (dom !== undefined && dom !== null) {
        if (!flag) {
          dom.removeAttribute('class');
        } else {
          dom.setAttribute('class', 'flickerAnimate');
        }
      }
    });
  };

  setCurrent = (target) => {
    if (target !== undefined && Tools.emptyObj(target.getAttribute('class'), '').indexOf('currentAnimate') < 0) {
      target.setAttribute('class', Tools.emptyObj(target.getAttribute('class'), '') + ' currentAnimate');
    }
  };

  removeCurrent = () => {
    Array.prototype.slice.apply(document.getElementsByClassName('currentAnimate')).forEach((dom) => {
      if (dom !== undefined) {
        dom.setAttribute('class', Tools.emptyObj(dom.getAttribute('class'), '').replace('currentAnimate', ''));
      }
    });
  };

  renderSiblingsClick = (array) => {
    const domList = array.map((detail) => this.getDom(detail.id));
    domList.forEach((dom) => {
      Array.prototype.slice.call(dom.parentNode.children).filter((child) => domList.indexOf(child) === -1 && domList.filter((d) => child.contains(d)).length === 0).forEach((child) => {
        child.setAttribute('style', Tools.emptyObj(child.getAttribute('style'), '') + ';pointer-events:none;');
      });
    });
  };

  getDom = (id) => {
    const dom = document.getElementById(id);
    if (dom !== undefined && dom !== null) {
      return dom;
    }
    const parentNode = id.indexOf('@') > -1 ? document.getElementById(id.substring(0, id.lastIndexOf('@'))) : document.getElementById(id.substring(0, id.lastIndexOf('_') + 1));
    const index = id.indexOf('@') > -1 ? Tools.emptyNumber(id.substring(id.lastIndexOf('@') + 1)) : Tools.emptyNumber(id.substring(id.lastIndexOf('_') + 1));
    if (parentNode !== undefined && parentNode !== null && parentNode.children.length > index) {
      return parentNode.children[index];
    }
    return undefined;
  };
}

export default DomRender;
