// const cloudUrl = 'http://localhost:5666/';
const cloudUrl = 'https://dqcloud-apis.daqo-cloud.com/platform/dictionary/';
const dataUrl = 'https://dqcloud-apis.daqo-cloud.com/platform/search-center/torch/';
// const cloudUrl = 'http://172.16.49.139:5666/';
// const dataUrl = 'http://172.16.49.133:9080/torch/';
// if (process.env.NODE_ENV === undefined || process.env.NODE_ENV === 'production') {
//   cloudUrl = 'https://apis.daqo-cloud.com/DaQOCloudCover/';
//   dataUrl = 'https://apis.daqo-cloud.com:9080/torch/';
// }

const resultMapping = {
  json: (res) => res.json(),
  blob: (res) => res.blob(),
  text: (res) => res.text(),
};

class Api {
  sendRequest(
    {
      url,
      method = 'GET',
      mode = 'cors',
      body,
      timeout = 20000,
      // contentType = 'application/json;charset=utf-8',
      resultType = 'json',
      extra = undefined,
      errorThrow = false,
      type = 0,
    } = {},
  ) {
    const fetchRequest = fetch(`${type === 0 ? cloudUrl : dataUrl}${url}`, {
      method,
      mode,
      headers: {
        // 'Content-Type': contentType,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
      },
      body,
    });
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('request timeout...'));
      }, timeout);
    });
    return Promise.race([fetchRequest, timeoutPromise]).then((res) => {
      if (res.ok) {
        try {
          return resultMapping[resultType](res);
        } catch (e) {
          throw new Error(e.message);
        }
      }
      throw new Error('request error');
    }).then((res) => (resultType !== 'blob' ? (extra !== undefined ? {ok: true, body: res, extra} : {ok: true, body: res}) : res)).catch((error) => {
      if (errorThrow) {
        throw error;
      }
      return {ok: false, body: error};
    });
  }

  generateRequest(
    {
      url,
      method = 'GET',
      mode = 'cors',
      body,
      contentType = 'application/json',
    } = {},
  ) {
    return fetch(`${type === 0 ? cloudUrl : dataUrl}${url}`, {
      method,
      mode,
      headers: {'Content-Type': contentType},
      body,
    }).then((res) => res.json()).catch((error) => new Error(error));
  }
}

export default Api;
