class Tools {
  static formatIcon(iconName, status) {
    if (status === undefined) {
      return iconName;
    }
    const icon = iconName.substring(0, iconName.lastIndexOf('.'));
    const { off, on } = {
      off: '0',
      on: '1',
      ...status.desc,
    };
    if (status.value === undefined || status.value === off) {
      return icon + '_off' + iconName.substring(iconName.lastIndexOf('.'));
    }
    if (status.value !== undefined && status.value === on) {
      return icon + '_on' + iconName.substring(iconName.lastIndexOf('.'));
    }
    return icon + '_off' + iconName.substring(iconName.lastIndexOf('.'));
  }

  static defaultBackColor() {
    return '#111617';
  }

  static timesValue(value, times = 1000) {
    try {
      if (value === undefined || value === '-') {
        return value;
      }
      return parseFloat(value) * times;
    } catch (e) {
      return value;
    }
  }

  static getValue(value, defaultValue = '--') {
    if (value === undefined || value === '') {
      return defaultValue;
    }
    return value;
  }

  static dateFormat(date, pattern, locate = 'en') {
    const format = {
      yyyy: () => date.getFullYear(),
      'yyyy-MM': () => date.getFullYear() + (locate === 'en' ? '-' : '年') + ((date.getMonth() + 1) + '').padStart(2, '0'),
      'yyyy-MM-dd': () => date.getFullYear() + (locate === 'en' ? '-' : '年') + ((date.getMonth() + 1) + '').padStart(2, '0') + (locate === 'en' ? '-' : '月') + (date.getDate() + '').padStart(2, '0') + (locate === 'cn' ? '日' : ''),
      'yyyy-MM-dd HH': () => date.getFullYear() + (locate === 'en' ? '-' : '年') + ((date.getMonth() + 1) + '').padStart(2, '0') + (locate === 'en' ? '-' : '月') + (date.getDate() + '').padStart(2, '0') + (locate === 'cn' ? '日' : '') + ' ' + (date.getHours() + '').padStart(2, '0'),
      'yyyy-MM-dd HH:mm': () => date.getFullYear() + (locate === 'en' ? '-' : '年') + ((date.getMonth() + 1) + '').padStart(2, '0') + (locate === 'en' ? '-' : '月') + (date.getDate() + '').padStart(2, '0') + (locate === 'cn' ? '日' : '') + ' ' + (date.getHours() + '').padStart(2, '0') + ':' + (date.getMinutes() + '').padStart(2, '0'),
      'yyyy-MM-dd HH:mm:ss': () => date.getFullYear() + (locate === 'en' ? '-' : '年') + ((date.getMonth() + 1) + '').padStart(2, '0') + (locate === 'en' ? '-' : '月 ') + (date.getDate() + '').padStart(2, '0') + (locate === 'cn' ? '日' : '') + ' ' + (date.getHours() + '').padStart(2, '0') + ':' + (date.getMinutes() + '').padStart(2, '0') + ':' + (date.getSeconds() + '').padStart(2, '0'),
      'HH:mm': () => (date.getHours() + '').padStart(2, '0') + ':' + (date.getMinutes() + '').padStart(2, '0'),
    };
    return format[pattern]();
  }

  static formatFilename(filename) {
    if (filename !== undefined && filename !== null && filename.indexOf('.') > -1 && filename.indexOf('_') > -1) {
      return [
        `${filename.substring(0, filename.lastIndexOf('_'))}.svg`,
        Tools.dateFormat(
          Tools.timestamp2Date(filename.substring(filename.lastIndexOf('_') + 1, filename.lastIndexOf('.'))),
          'yyyy-MM-dd HH:mm:ss',
        ),
      ];
    }
    return ['', ''];
  }

  static timestamp2Date(time) {
    const date = new Date();
    date.setTime(time);
    return date;
  }

  static dateStart(date, type) {
    const format = {
      year: () => {
        date.setMonth(0);
        date.setDate(1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
      },
      month: () => {
        date.setDate(1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
      },
      date: () => {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
      },
    };
    return format[type]().getTime();
  }

  static dateEnd(date, type) {
    return this.dateStart(this.addDate(date, 1, type), type) - 1;
  }

  static addDate(d, num, type = 'month') {
    const date = new Date(d);
    const format = {
      year: () => {
        date.setFullYear(date.getFullYear() + num);
        return date;
      },
      month: () => {
        date.setMonth(date.getMonth() + num);
        return date;
      },
      date: () => {
        date.setDate(date.getDate() + num);
        return date;
      },
      hour: () => {
        date.setHours(date.getHours() + num);
        return date;
      },
      minute: () => {
        date.setMinutes(date.getMinutes() + num);
        return date;
      },
      second: () => {
        date.setSeconds(date.getSeconds() + num);
        return date;
      },
    };
    return format[type]();
  }

  static remRadio(px, ipad = false) {
    let viewport = window.innerWidth;
    if (viewport <= (ipad ? 850 : 1280)) {
      viewport = (ipad ? 850 : 1280);
    }
    try {
      return parseFloat(px) * (viewport / 1920).toFixed(2);
    } catch (e) {
      return px;
    }
  }

  static pxRadio(rem) {
    let viewport = window.innerWidth;
    if (viewport <= 1280) {
      viewport = 1280;
    }
    try {
      return Math.ceil(viewport * 100 * parseFloat(rem) / 1920);
    } catch (e) {
      return rem;
    }
  }

  static colorList() {
    return ['#faad14', '#52c41a', '#fa541c', '#eb2f96', '#13c2c2', '#f5222d'];
  }

  static gradualColors() {
    return [
      ['#ffd666', '#ad6800'],
      ['#95de64', '#237804'],
      ['#ff9c6e', '#ad2102'],
      ['#ff85c0', '#9e1068'],
      ['#5cdbd3', '#006d75'],
      ['#ff7875', '#a8071a'],
    ];
  }

  static opacityColors() {
    return [
      ['#faad14', 'rgba(210,144,18,0.2)'],
      ['#52c41a', 'rgba(72,173,24,0.2)'],
      ['#fa541c', 'rgba(239,80,27,0.2)'],
      ['#eb2f96', 'rgba(219,44,140,0.2)'],
      ['#13c2c2', 'rgba(18,180,180,0.2)'],
      ['#f5222d', 'rgba(229,32,42,0.2)'],
    ];
  }

  static formatJson(text) {
    try {
      return JSON.parse(text);
    } catch (e) {
      return text;
    }
  }

  static emptyNumber(t, value = 0) {
    if (t === undefined || t === null || t === '' || t === 'NaN') {
      return value;
    }
    return parseFloat(t);
  }

  static toInteger(t) {
    try {
      return parseInt(t, 10);
    } catch (e) {
      return 0;
    }
  }

  static fixNumber(text, fixed = 2) {
    if (text !== undefined && text !== null && text !== '' && /[1-9]?[0-9]*(\.[0-9]+)?/.test(text)) {
      return parseFloat(text).toFixed(fixed);
    }
    return text;
  }

  static ifMobile() {
    const {
      userAgent: ua,
    } = navigator;
    return ua.toLowerCase().indexOf('ipad') > -1 || !!ua.toLowerCase().match(/applewebkit.*mobile.*/) || !!ua.toLowerCase().match(/\(i[^;]+;( u;)? cpu.+mac os x/) || ua.toLowerCase().indexOf('android') > -1 || ua.toLowerCase().indexOf('adr') > -1 || ua.toLowerCase().indexOf('iphone') > -1;
  }

  static first(array, defaultValue = {}) {
    return array && array.length > 0 ? array[0] : defaultValue;
  }

  static emptyObj(obj, defaultValue = {}) {
    return obj !== undefined && obj !== null ? obj : defaultValue;
  }

  static randomString(L) {
    let s = '';
    const randomchar = () => {
      const n = Math.floor(Math.random() * 62);
      if (n < 10) return n;
      if (n < 36) return String.fromCharCode(n + 55);
      return String.fromCharCode(n + 61);
    };
    while (s.length < L) s += randomchar();
    return s;
  }

  static addSalt(code) {
    const random = this.randomString(10);
    const mapping = {
      0: [1, 4, 5, 8],
      1: [2, 6, 7, 9],
    };
    const filter = mapping[random.substring(0, 1).toLowerCase() === random.substring(1, 2) ? 0 : 1];
    return random.split('').map((r, index) => {
      const ind = filter.indexOf(index);
      if (ind > -1) {
        return code.substring(ind, ind + 1);
      }
      return r;
    }).join('');
  }

  static removeSalt(random) {
    const mapping = {
      0: [1, 4, 5, 8],
      1: [2, 6, 7, 9],
    };
    const filter = mapping[random.substring(0, 1).toLowerCase() === random.substring(1, 2) ? 0 : 1];
    return random.split('').filter((r, index) => filter.indexOf(index) > -1).join('');
  }

  static isJson(text) {
    try {
      JSON.parse(text);
      return true;
    } catch (e) {
      return false;
    }
  }

  static isObject(value) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
  }

  static debounce(func, wait, options) {
    const freeGlobal = typeof global === 'object' && global !== null && global.Object === Object && global;
    // eslint-disable-next-line eqeqeq
    const freeGlobalThis = typeof globalThis === 'object' && globalThis !== null && globalThis.Object == Object && globalThis;

    // eslint-disable-next-line no-restricted-globals
    const freeSelf = typeof self === 'object' && self !== null && self.Object === Object && self;

    // eslint-disable-next-line no-new-func
    const root = freeGlobalThis || freeGlobal || freeSelf || Function('return this')();

    let lastArgs;
    let lastThis;
    let maxWait;
    let result;
    let timerId;
    let lastCallTime;

    let lastInvokeTime = 0;
    let leading = false;
    let maxing = false;
    let trailing = true;

    // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
    const useRAF = (!wait && wait !== 0 && typeof root.requestAnimationFrame === 'function');

    if (typeof func !== 'function') {
      throw new TypeError('Expected a function');
    }
    // eslint-disable-next-line no-param-reassign
    wait = +wait || 0;
    if (this.isObject(options)) {
      leading = !!options.leading;
      maxing = 'maxWait' in options;
      maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait;
      trailing = 'trailing' in options ? !!options.trailing : trailing;
    }

    function invokeFunc(time) {
      const args = lastArgs;
      const thisArg = lastThis;

      // eslint-disable-next-line no-multi-assign
      lastArgs = lastThis = undefined;
      lastInvokeTime = time;
      result = func.apply(thisArg, args);
      return result;
    }

    // eslint-disable-next-line no-shadow
    function startTimer(pendingFunc, wait) {
      if (useRAF) {
        root.cancelAnimationFrame(timerId);
        return root.requestAnimationFrame(pendingFunc);
      }
      return setTimeout(pendingFunc, wait);
    }

    // eslint-disable-next-line consistent-return
    function cancelTimer(id) {
      if (useRAF) {
        return root.cancelAnimationFrame(id);
      }
      clearTimeout(id);
    }

    function leadingEdge(time) {
      // Reset any `maxWait` timer.
      lastInvokeTime = time;
      // Start the timer for the trailing edge.
      // eslint-disable-next-line no-use-before-define
      timerId = startTimer(timerExpired, wait);
      // Invoke the leading edge.
      return leading ? invokeFunc(time) : result;
    }

    function remainingWait(time) {
      const timeSinceLastCall = time - lastCallTime;
      const timeSinceLastInvoke = time - lastInvokeTime;
      const timeWaiting = wait - timeSinceLastCall;

      return maxing
        ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
        : timeWaiting;
    }

    function shouldInvoke(time) {
      const timeSinceLastCall = time - lastCallTime;
      const timeSinceLastInvoke = time - lastInvokeTime;

      // Either this is the first call, activity has stopped and we're at the
      // trailing edge, the system time has gone backwards and we're treating
      // it as the trailing edge, or we've hit the `maxWait` limit.
      return (lastCallTime === undefined || (timeSinceLastCall >= wait) || (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
    }

    // eslint-disable-next-line consistent-return
    function timerExpired() {
      const time = Date.now();
      if (shouldInvoke(time)) {
        // eslint-disable-next-line no-use-before-define
        return trailingEdge(time);
      }
      // Restart the timer.
      timerId = startTimer(timerExpired, remainingWait(time));
    }

    function trailingEdge(time) {
      timerId = undefined;

      // Only invoke if we have `lastArgs` which means `func` has been
      // debounced at least once.
      if (trailing && lastArgs) {
        return invokeFunc(time);
      }
      // eslint-disable-next-line no-multi-assign
      lastArgs = lastThis = undefined;
      return result;
    }

    function cancel() {
      if (timerId !== undefined) {
        cancelTimer(timerId);
      }
      lastInvokeTime = 0;
      // eslint-disable-next-line no-multi-assign
      lastArgs = lastCallTime = lastThis = timerId = undefined;
    }

    function flush() {
      return timerId === undefined ? result : trailingEdge(Date.now());
    }

    function pending() {
      return timerId !== undefined;
    }

    function debounced(...args) {
      const time = Date.now();
      const isInvoking = shouldInvoke(time);

      lastArgs = args;
      lastThis = this;
      lastCallTime = time;

      if (isInvoking) {
        if (timerId === undefined) {
          return leadingEdge(lastCallTime);
        }
        if (maxing) {
          // Handle invocations in a tight loop.
          timerId = startTimer(timerExpired, wait);
          return invokeFunc(lastCallTime);
        }
      }
      if (timerId === undefined) {
        timerId = startTimer(timerExpired, wait);
      }
      return result;
    }

    debounced.cancel = cancel;
    debounced.flush = flush;
    debounced.pending = pending;
    return debounced;
  }
}

export default Tools;
