import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from 'react-router-dom';
import SvgPicker from '../../containers/svg-picker/svg-picker-container';
import SvgLoader from '../../containers/svg-loader/svg-loader-container';
import VisibleLoading from '../../containers/loading/loading';
import VisibleMessage from '../../containers/message-box/message-box';

import styles from './app.less';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error);
    // eslint-disable-next-line no-console
    console.log(errorInfo);
  }

  render() {
    return <div className={styles.main}>
      <Switch>
        <Route path={'/:projectId/:filename/:edit'} component={SvgLoader}/>
        <Route path={'/svg'} exact component={SvgLoader}/>
        <Route path={`${this.props.match.path}/`} exact component={SvgPicker}/>
      </Switch>
      <VisibleLoading/>
      <VisibleMessage/>
    </div>;
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(App);
