import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import SystemSet from '../../components/system-set/system-set';

import systemSetActions from '../../redux/system-set/system-set.action';
import loadingActions from '../../redux/loading/loading.action';
import messageActions from '../../redux/message-box/message-box.action';
import svgLoaderActions from '../../redux/svg-loader/svg-loader.action';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    switchSystemSet: systemSetActions.activeSystemSetAction,
    setLoading: loadingActions.loadingAction,
    setMessage: messageActions.messageAction,
    setBackgroundColor: svgLoaderActions.setBackgroundColor,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemSet);
