import DrawerStyles from '../../entity/drawer';
import TYPES from './drawer.types';

const initialState = {
  status: DrawerStyles.state.shrink,
  nail: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SWITCH_DRAWER:
      return {
        ...state,
        status: action.data,
      };
    case TYPES.SWITCH_NAIL:
      return {
        ...state,
        nail: !state.nail,
      };
    default:
      return state;
  }
};
