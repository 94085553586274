import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import deviceControlActions from '../../redux/device-control/device-control.action';
import loadingActions from '../../redux/loading/loading.action';
import messageActions from '../../redux/message-box/message-box.action';
import DeviceControl from '../../components/device-control/device-control';

const mapStateToProps = (state) => ({
  show: state.deviceSet.show,
  projectSvgList: state.svgPicker.svgList,
  config: state.svgLoader.currentDetails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    switchMenu: deviceControlActions.activeDeviceControl,
    setLoading: loadingActions.loadingAction,
    setMessage: messageActions.messageAction,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceControl);
