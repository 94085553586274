import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './svg-list-menu.less';

class SvgListMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className={styles.main} style={
      {
        top: `${this.props.top}px`,
        left: `${this.props.left}px`,
      }
    } onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
    }}>
      <div className={styles.menu} onClick={() => {
        this.props.onClick();
      }}>删除</div>
    </div>;
  }
}

SvgListMenu.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SvgListMenu;
