import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SvgLoader from '../../pages/svg-loader/svg-loader';
import svgLoaderActions from '../../redux/svg-loader/svg-loader.action';
import svgPickerActions from '../../redux/svg-picker/svg-picker.action';
import drawerActions from '../../redux/drawer/drawer.action';
import contextMenuActions from '../../redux/context-menu/context-menu.action';
import messageActions from '../../redux/message-box/message-box.action';
import deviceMenuAction from '../../redux/device-menu/device-menu.action';
import deviceSetActions from '../../redux/device-set/device-set.action';
import deviceControlActions from '../../redux/device-control/device-control.action';

const mapStateToProps = (state) => ({
  drawer: {
    status: state.drawer.status,
    nail: state.drawer.nail,
  },
  contextMenu: {
    status: state.contextMenu.status,
  },
  systemSet: {
    status: state.systemSet.status,
  },
  currentDevice: state.svgLoader.currentDevice,
  currentDetails: state.svgLoader.currentDetails,
  showPrepared: state.contextMenu.showPrepared,
  projectSvgList: state.svgPicker.svgList,
  backgroundColor: state.svgLoader.backgroundColor,
  deviceMenuStatus: state.deviceMenu.show,
  deviceSetStatus: state.deviceSet.show,
  deviceControlStatus: state.deviceControl.show,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    switchDrawer: drawerActions.switchDrawerAction,
    switchContextMenu: contextMenuActions.activeContextMenuAction,
    switchPrepared: contextMenuActions.showPrepared,
    setCurrentDevice: svgLoaderActions.setCurrentDevice,
    setCurrentDetails: svgLoaderActions.setCurrentDetails,
    setLoading: svgLoaderActions.loadingAction,
    setMessage: messageActions.messageAction,
    getSvgList: svgPickerActions.getSvgAction,
    setBackgroundColor: svgLoaderActions.setBackgroundColor,
    switchDeviceMenu: deviceMenuAction.activeDeviceMenu,
    switchDeviceSet: deviceSetActions.activeDeviceSet,
    switchDeviceControl: deviceControlActions.activeDeviceControl,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SvgLoader);
