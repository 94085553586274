import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SvgPicker from '../../pages/svg-picker/svg-picker';
import actions from '../../redux/svg-picker/svg-picker.action';

const mapStateToProps = (state) => ({
  projectList: state.svgPicker.projectList,
  svgList: state.svgPicker.svgList,
  currentProject: state.svgPicker.currentProject,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getProjects: actions.getProjectAction,
    getSvg: actions.getSvgAction,
    pickProject: actions.pickProjectAction,
    uploadSvg: actions.uploadSvgAction,
    pickSvg: actions.pickSvgAction,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SvgPicker);
