import TYPES from './context-menu.types';

const initialState = {
  status: false,
  showPrepared: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.ACTIVE_CONTEXT_MENU:
      return {
        ...state,
        status: action.data,
      };
    case TYPES.SHOW_PREPARED:
      return {
        ...state,
        showPrepared: !state.showPrepared,
      };
    default:
      return state;
  }
};
