const LoadingStyles = {
  theme: {
    light: 'light',
    dark: 'dark',
  },
  type: {
    circle: 'circle',
  },
};

export default LoadingStyles;
