import TYPES from './svg-loader.types';

const setCurrentDetails = (details) => (dispatch) => {
  dispatch({
    type: TYPES.SET_CURRENT_DETAIL,
    data: details,
  });
};

const setCurrentDevice = (device) => (dispatch) => {
  dispatch({
    type: TYPES.SET_CURRENT_DEVICE,
    data: device,
  });
};

const loadingAction = (loading) => (dispatch) => {
  dispatch({
    type: TYPES.SET_LOADING,
    data: loading,
  });
};

const setBackgroundColor = (color) => (dispatch) => {
  dispatch({
    type: TYPES.SET_BACKGROUND_COLOR,
    data: color,
  });
};

export default {
  setCurrentDetails,
  setCurrentDevice,
  loadingAction,
  setBackgroundColor,
};
