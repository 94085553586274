import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './confirm-panel.less';
import Button from '../button/button';

class ConfirmPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className={styles.main}>
      <div className={styles.content}>
        {
          this.props.text
        }
      </div>
      <div className={styles.btnBox}>
        <Button onClick={this.props.confirm} text={'确认'} fontSize={'0.14rem'}/>
        <Button onClick={this.props.cancel} text={'取消'} icon={'cancel_white.png'} fontSize={'0.14rem'}/>
      </div>
    </div>;
  }
}

ConfirmPanel.propTypes = {
  text: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

ConfirmPanel.defaultProps = {
  text: '确认删除吗？',
};

export default ConfirmPanel;
