import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';
import reduxThunk from 'redux-thunk';

import loadingReducer from '../loading/loading.reducer';
import svgPickerReducer from '../svg-picker/svg-picker.reducer';
import messageBoxReducer from '../message-box/message-box.reducer';
import drawerReducer from '../drawer/drawer.reducer';
import svgLoaderReducer from '../svg-loader/svg-loader.reducer';
import systemSetReducer from '../system-set/system-set.reducer';
import contextMenuReducer from '../context-menu/context-menu.reducer';
import deviceMenuReducer from '../device-menu/device-menu.reducer';
import deviceSetReducer from '../device-set/device-set.reducer';
import deviceControlReducer from '../device-control/device-control.reducer';

const reducer = combineReducers({
  loading: loadingReducer,
  svgPicker: svgPickerReducer,
  messageBox: messageBoxReducer,
  drawer: drawerReducer,
  svgLoader: svgLoaderReducer,
  systemSet: systemSetReducer,
  contextMenu: contextMenuReducer,
  deviceMenu: deviceMenuReducer,
  deviceSet: deviceSetReducer,
  deviceControl: deviceControlReducer,
});

const middleWares = [
  reduxThunk,
];

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

export default createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleWares)),
);
