import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '../../api/api';
import Tools from '../../utils/tools';

import SvgList from '../../components/svg-list/svg-list';

import styles from './svg-picker.less';
import InputText from '../../components/input-text/input-text';
import url from '../../api/url';

class SvgPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      filterPj: false,
      filterCont: '',
    };
  }

  componentDidMount() {
    this.file = React.createRef();
    this.api = new Api();
    if (this.props.projectList.length === 0) {
      this.props.getProjects().then((res) => {
        if (res.ok) {
          const pjs = res.body.body;
          if (Tools.first(pjs, {}).strKey !== undefined) {
            setTimeout(() => {
              this.props.getSvg(Tools.first(pjs, {}).strKey);
            }, 600);
          }
        }
      });
    }
  }

  filePicker = (event) => {
    const fileList = event.target.files;
    if (fileList.length > 0 && /[\d\D]+\.svg$/.test(fileList[0].name)) {
      const name = `${fileList[0].name.substring(0, fileList[0].name.lastIndexOf('.'))}_${new Date().getTime()}.svg`;
      const newFile = new File([fileList[0]], name, {
        type: 'image/svg+xml',
      });
      this.setState({
        file: newFile,
      });
    } else {
      this.setState({
        file: {},
      });
    }
  };

  submit = () => {
    if (this.state.file.name !== undefined) {
      const formData = new FormData();
      formData.append('svg', this.state.file);
      formData.append('projectId', this.props.currentProject.strKey);
      this.props.uploadSvg(formData).then(() => {
        this.props.pickProject(this.props.currentProject);
        this.setState({
          file: {},
        });
      });
    }
  };

  svgPicker = (svg) => {
    // http://172.16.49.137:5880/svg-center/MQ==/5pyq5qCH6aKYLTRfMTU4OTAxNTg3NzE2OC5zdmc%3D/xIMhapA=O=
    // http://172.16.49.137:5880/svg-center/MQ==/5pyq5qCH6aKYLTRfMTU4OTAxNTg3NzE2OC5zdmc%3D/vYMIMnQ=s=
    this.props.history.push(`/${window.btoa(this.props.currentProject.strKey)}/${encodeURIComponent(window.btoa(unescape(encodeURIComponent(svg))))}/${Tools.addSalt(window.btoa('1'))}`);
  };

  pjPicker = (project) => {
    if (this.props.currentProject.strKey !== project.strKey) {
      this.props.pickProject(project);
    }
  };

  showFilter = () => {
    this.setState((prevState) => ({
      filterPj: !prevState.filterPj,
    }));
  };

  pjFilterChange = (filter) => {
    this.setState({
      filterCont: filter,
    });
  };

  removeSvg = (svg) => this.api.sendRequest({
    url: `${url.REMOVE_SVG}?filename=${encodeURIComponent(window.btoa(unescape(encodeURIComponent(`${this.props.currentProject.strKey}/svg/${svg}`))))}`,
    resultType: 'json',
    method: 'delete',
    errorThrow: true,
  }).then(() => {
    this.props.pickProject(this.props.currentProject);
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });

  render() {
    return <div className={styles.main}>
      <div className={styles.pjList}>
        <div className={styles.title} data-value={'工程列表'}>
          <div className={styles.project}/>
          <div className={styles.filter} onClick={this.showFilter}/>
        </div>
        <div className={styles.pjContent}>
          <div className={styles.filter} style={{
            height: `${this.state.filterPj ? '0.5rem' : '0'}`,
          }}>
            <InputText width={'4rem'} placeholder={'请输入工程过滤条件'} getValue={this.pjFilterChange}/>
          </div>
          <div className={styles.projectList}>
            {
              this.props.projectList.filter((pj) => !this.state.filterPj || pj.strNameInfo.indexOf(this.state.filterCont) > -1).map((project) => (
                <div
                  className={`${styles.pjItem} ${project.strKey === this.props.currentProject.strKey ? styles.active : ''}`}
                  onClick={() => this.pjPicker(project)} key={project.strKey}>{
                  project.strNameInfo
                }</div>
              ))
            }
          </div>
        </div>
      </div>
      <div className={styles.panel}>
        <div className={styles.svgList}>
          <div className={styles.title} data-value={'SVG 列表'}>
            <div className={styles.grid}/>
          </div>
          <SvgList svgList={this.props.svgList} onClick={this.svgPicker} removeSvg={this.removeSvg}/>
        </div>
        <div className={styles.title} data-value={'新增 SVG'}>
          <div className={styles.addImage}/>
        </div>
        <div className={styles.fileBox}>
          <div className={styles.file} data-value={Tools.formatFilename(this.state.file.name)[0]}/>
          <div className={styles.submit} onClick={this.submit}/>
          <input type={'file'} className={styles.invisibleFile} ref={this.file}
                 onChange={(event) => this.filePicker(event)} accept={'image/svg+xml'}/>
        </div>
      </div>
    </div>;
  }
}

SvgPicker.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getProjects: PropTypes.func.isRequired,
  projectList: PropTypes.array.isRequired,
  getSvg: PropTypes.func.isRequired,
  svgList: PropTypes.array.isRequired,
  currentProject: PropTypes.object.isRequired,
  pickProject: PropTypes.func.isRequired,
  uploadSvg: PropTypes.func.isRequired,
  pickSvg: PropTypes.func.isRequired,
};

export default SvgPicker;
