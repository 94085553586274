import TYPES from './loading.types';
import LoadingStyles from '../../entity/loading';

const initialState = {
  status: false,
  theme: LoadingStyles.theme.light,
  type: LoadingStyles.type.circle,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.LOADING_START:
      return {
        ...state,
        status: true,
        ...action.data,
      };
    case TYPES.LOADING_END:
      return {
        ...state,
        status: false,
        ...action.data,
      };
    default:
      return state;
  }
};
