import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './input-text.less';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  handleChange = (event) => {
    const {value} = {
      value: event.target.value,
    };
    this.setState({
      value,
    }, () => {
      if (this.props.getValue !== undefined) {
        this.props.getValue(value);
      }
    });
  };

  search = () => {
    this.props.search(this.state.value);
  };

  focus = (event) => {
    if (this.props.onFocusEnter && event.key === 'Enter') {
      this.props.onFocusEnter(this.state.value);
    }
  };

  render() {
    return <div className={styles.panel}><input className={styles.main} type={this.props.type} style={
      {
        width: this.props.width,
        height: this.props.height,
        padding: this.props.icon ? '0 0.4rem 0 0.1rem' : '0 0.1rem',
      }
    } placeholder={this.props.placeholder} onChange={(event) => this.handleChange(event)}
                                                onKeyDown={(event) => this.focus(event)}/>
      {
        this.props.icon && <span className={styles.icon} style={
          {
            backgroundImage: `url(${require(`../../common/images/${this.props.icon}`)})`,
          }
        } onClick={this.search}/>
      }
    </div>;
  }
}

InputText.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  getValue: PropTypes.func,
  icon: PropTypes.string,
  search: PropTypes.func,
  onFocusEnter: PropTypes.func,
};

InputText.defaultProps = {
  width: '1.8rem',
  height: '0.32rem',
  placeholder: '请输入查询条件',
  type: 'text',
};

export default InputText;
