import TYPES from '../types';
import Api from '../../api/api';
import url from '../../api/url';
import Tools from '../../utils/tools';
import messageActions from '../message-box/message-box.action';
import MessageStyles from '../../entity/message';

const api = new Api();
const svgReg = /[\d\D]+.svg$/;

const getProjectAction = () => (dispatch) => {
  dispatch({
    type: TYPES.LOADING_START,
    data: {
      theme: 'dark',
    },
  });
  return Promise.all([
    api.sendRequest({
      url: url.GET_PROJECTS,
      errorThrow: true,
    }),
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('ok');
      }, 1000);
    }),
  ]).then((results) => {
    dispatch({
      type: TYPES.LOADING_END,
      data: {
        theme: 'dark',
      },
    });
    if (results[0].ok && results[0].body.code === 0) {
      const pjs = results[0].body.body;
      dispatch({
        type: TYPES.GET_PROJECTS,
        data: {
          projectList: pjs,
          currentProject: Tools.first(pjs, {}),
        },
      });
      return {
        ok: true,
        body: results[0].body,
      };
    }
    // eslint-disable-next-line no-console
    console.log(results[0].body);
    dispatch(messageActions.messageAction({
      status: true,
    }));
    return {
      ok: false,
      body: results[0].body,
    };
  }).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
    dispatch({
      type: TYPES.LOADING_END,
      data: {
        theme: 'dark',
      },
    });
    dispatch(messageActions.messageAction({
      status: true,
    }));
    return {
      ok: false,
      body: err,
    };
  });
};

const getSvgAction = (projectId, theme = 'dark') => (dispatch) => {
  dispatch({
    type: TYPES.LOADING_START,
    data: {
      theme,
    },
  });
  Promise.all([
    api.sendRequest({
      url: `${url.GET_SVG}/${projectId}`,
      errorThrow: true,
    }),
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('ok');
      }, 1000);
    }),
  ]).then((results) => {
    dispatch({
      type: TYPES.LOADING_END,
      data: {
        theme: 'dark',
      },
    });
    if (results[0].ok && results[0].body.code === 0) {
      dispatch({
        type: TYPES.GET_SVG,
        data: {
          svgList: results[0].body.body.filter((svg) => svgReg.test(svg)),
        },
      });
    } else {
      // eslint-disable-next-line no-console
      console.log(results[0].body);
      dispatch(messageActions.messageAction({
        status: true,
      }));
    }
    return results[0].body;
  }).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
    dispatch({
      type: TYPES.LOADING_END,
      data: {
        theme: 'dark',
      },
    });
    dispatch(messageActions.messageAction({
      status: true,
    }));
  });
};

const uploadSvgAction = (body) => (dispatch) => {
  dispatch({
    type: TYPES.LOADING_START,
    data: {
      theme: 'dark',
    },
  });
  return Promise.all([
    api.sendRequest({
      url: url.UPLOAD_SVG,
      method: 'POST',
      body,
      errorThrow: true,
    }),
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('ok');
      }, 1000);
    }),
  ]).then((results) => {
    dispatch({
      type: TYPES.LOADING_END,
      data: {
        theme: 'dark',
      },
    });
    if (results[0].ok && results[0].body.code === 0) {
      dispatch(messageActions.messageAction({
        status: true,
        text: '添加成功',
        theme: MessageStyles.theme.info,
      }));
    } else {
      // eslint-disable-next-line no-console
      console.log(results[0].body);
      dispatch(messageActions.messageAction({
        status: true,
      }));
    }
    return results[0].ok;
  }).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
    dispatch({
      type: TYPES.LOADING_END,
      data: {
        theme: 'dark',
      },
    });
    dispatch(messageActions.messageAction({
      status: true,
    }));
  });
};

const pickProjectAction = (project) => (dispatch) => {
  dispatch({
    type: TYPES.PICK_PROJECT,
    data: {
      currentProject: project,
    },
  });
  dispatch(getSvgAction(project.strKey));
};

const pickSvgAction = (svg) => (dispatch) => {
  dispatch({
    type: TYPES.PICK_SVG,
    data: svg,
  });
};

export default {
  getProjectAction,
  getSvgAction,
  uploadSvgAction,
  pickProjectAction,
  pickSvgAction,
};
