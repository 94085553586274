import TYPES from './system-set.types';

const activeSystemSetAction = (status) => (dispatch) => {
  dispatch({
    type: TYPES.ACTIVE_SYSTEM_SET,
    data: status,
  });
};

export default {
  activeSystemSetAction,
};
