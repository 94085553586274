import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './context-menu.less';

class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className={styles.main} style={
      {
        top: `${this.props.top}px`,
        left: `${this.props.left}px`,
      }
    }>
      <div className={`${styles.menu} ${this.props.prepared ? styles.active : ''}`} onClick={this.props.showPrepared}>已配设备</div>
      <div className={styles.line}/>
      <div className={styles.menu} onClick={() => this.props.history.push('/')}>返回首页</div>
      <div className={styles.line}/>
      <div className={styles.menu} onClick={() => this.props.switchSystemSet(true)}>全局设置</div>
      <div className={styles.line}/>
      <div className={styles.menu} onClick={() => this.props.switchExhibition()}>展示模式</div>
      <div className={styles.line}/>
      <div className={styles.menu} onClick={() => this.props.exportPackage()}>打包导出</div>
    </div>;
  }
}

ContextMenu.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  switchSystemSet: PropTypes.func.isRequired,
  showPrepared: PropTypes.func.isRequired,
  prepared: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  switchExhibition: PropTypes.func.isRequired,
  exportPackage: PropTypes.func.isRequired,
};

export default ContextMenu;
