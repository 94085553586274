import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DetailConfig from '@daqo/react-detail-config-panel';
import Api from '../../api/api';

import styles from './device-control.less';
import Tools from '../../utils/tools';
import LoadingStyles from '../../entity/loading';
import url from '../../api/url';
import MessageStyles from '../../entity/message';

class DeviceControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        deviceId: '',
        category: '',
        detailId: '',
      },
      control: {
        open: '',
        close: '',
      },
      eventId: '',
      preControl: false,
      sequenceId: '',
      test: '',
    };
  }

  componentDidMount() {
    this.api = new Api();
    const { config } = this.props;
    if (config.details !== undefined && config.details.control !== undefined) {
      const { control } = config.details;
      this.setState({
        detail: control.detail,
        control: control.config,
        eventId: control.eventId,
        sequenceId: control.sequenceId,
        preControl: control.preControl,
      });
    }
  }

  setDetails = (detail) => {
    const kvp = this.formatFVP(detail.strDescinfo);
    this.setState(() => ({
      detail: {
        deviceId: detail.deviceInfo.strKey,
        category: detail.strDetailCategory,
        detailId: detail.strKey,
      },
      unit: Tools.emptyObj(detail.strDim, ''),
      ...detail,
      kvp,
    }));
  };

  formatFVP = (json) => {
    if (Tools.isJson(json)) {
      return Object.entries(JSON.parse(json)).map((detail) => ({
        key: detail[0],
        value: detail[1],
      }));
    }
    return [];
  };

  judgeEmpty = (detail) => Tools.emptyObj(detail.category, '') !== '' && Tools.emptyObj(detail.detailId, '') && Tools.emptyObj(detail.deviceId, '');

  handleChange = (event, index, type) => {
    const { name, value } = {
      name: event.target !== undefined ? event.target.name : '',
      value: event.target !== undefined ? (event.target.type === 'checkbox' ? event.target.checked : event.target.value) : event,
    };
    this.setState((prevState) => {
      const mapping = {
        control: () => ({
          control: {
            ...prevState.control,
            [name]: value,
          },
        }),
        detail: () => ({
          detail: {
            ...prevState.detail,
            [name]: value,
          },
        }),
        default: () => ({
          [name]: value,
        }),
      };
      return mapping[type]();
    });
  };

  submit = () => {
    if (this.judgeEmpty(this.state.detail)) {
      this.props.setLoading({
        status: true,
        theme: LoadingStyles.theme.light,
      });
      const { filename, config, projectId } = this.props;
      const {
        detail,
        control,
        intLength,
        eventId,
        preControl,
        sequenceId,
      } = this.state;
      const formdata = new FormData();
      formdata.append('projectId', projectId);
      formdata.append('filename', window.btoa(unescape(encodeURIComponent(filename.substring(0, filename.lastIndexOf('.'))))));
      formdata.append('detail', JSON.stringify({
        id: config.id,
        control: {
          detail,
          config: control,
          eventId: Tools.toInteger(eventId),
          sequenceId: Tools.toInteger(sequenceId),
          preControl,
          codeLength: Tools.toInteger(intLength),
        },
      }));
      Promise.all([this.api.sendRequest({
        url: url.ADD_SVG_DETAIL,
        method: 'put',
        body: formdata,
        errorThrow: true,
      }), new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 1000);
      })]).then((results) => {
        const res = results[0];
        this.props.setLoading({
          status: false,
        });
        if (res.ok && res.body.code === 0) {
          this.props.complete();
        } else {
          this.props.setMessage({
            status: true,
            text: '更新数据点时出错了',
          });
        }
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.props.setLoading({
          status: false,
        });
        this.props.setMessage({
          status: true,
          text: '出错了',
        });
      });
    } else {
      this.props.setMessage({
        status: true,
        theme: MessageStyles.theme.warn,
        text: '未配置数据点',
      });
    }
  };

  setCheckbox = () => {
    this.setState((prevState) => ({
      preControl: !prevState.preControl,
    }));
  };

  render() {
    return <div className={styles.main} onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
    }}>
      <div className={styles.detailPicker}>
        <DetailConfig onCheck={this.setDetails} projectId={this.props.projectId}
                      theme={'light'} {...this.state.detailConfigSize} cssModules={true} appType={'ORIGIN'}/>
      </div>
      <div className={styles.detailSet}>
        <div className={styles.formItem} style={{
          width: '100%',
        }}>
          <div className={styles.desc}>描述</div>
          <div className={styles.item} style={{
            border: '0',
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height: '0.32rem',
            lineHeight: '0.32rem',
          }} title={this.state.strDescinfo}>{
            this.state.strDescinfo
          }</div>
        </div>
      </div>
      <div className={styles.detailSet}>
        <div className={styles.formItem} style={{
          width: '18%',
        }}>
          <div className={styles.desc}>是否预置</div>
          <div className={`${styles.checkbox} ${this.state.preControl ? styles.checked : styles.unchecked}`} onClick={this.setCheckbox}/>
        </div>
        <div className={styles.formItem} style={{
          width: '35%',
        }}>
          <div className={styles.desc}>字段长度</div>
          <input type={'text'} className={styles.item} name={'intLength'} placeholder={'请输入字段长度'}
                 value={this.state.intLength}
                 onChange={(event) => this.handleChange(event, '', 'default')}/>
        </div>
        <div className={styles.formItem} style={{
          width: '35%',
        }}>
          <div className={styles.desc}>事件 ID</div>
          <input type={'text'} className={styles.item} name={'eventId'} placeholder={'请输入对应事件 ID'}
                 value={this.state.eventId}
                 onChange={(event) => this.handleChange(event, '', 'default')}/>
        </div>
      </div>
      <div className={styles.detailSet}>
        <div className={styles.formItem} style={{
          width: '250px',
        }}>
          <div className={styles.desc}>开</div>
          <input type={'text'} className={styles.item} name={'open'} placeholder={'请输入开关打开判定值'}
                 value={this.state.control.open}
                 onChange={(event) => this.handleChange(event, '', 'control')}/>
        </div>
        <div className={styles.formItem} style={{
          width: '250px',
        }}>
          <div className={styles.desc}>合</div>
          <input type={'text'} className={styles.item} name={'close'} placeholder={'请输入开关关合判定值'}
                 value={this.state.control.close}
                 onChange={(event) => this.handleChange(event, '', 'control')}/>
        </div>
      </div>
      <div className={styles.submit} onClick={this.submit}/>
    </div>;
  }
}

DeviceControl.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  switchMenu: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  complete: PropTypes.func.isRequired,
};

export default DeviceControl;
