import TYPES from './device-set.types';

const initState = {
  show: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case TYPES.ACTIVE_DEVICE_SET:
      return {
        ...state,
        show: action.data,
      };
    default:
      return state;
  }
};
