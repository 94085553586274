import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './device-set.less';
import ComboBox from '../combo-box/combo-box';
import Tools from '../../utils/tools';
import Button from '../button/button';
import Api from '../../api/api';
import LoadingStyles from '../../entity/loading';
import url from '../../api/url';

class DeviceSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target: props.config.details.redirectTarget,
    };
  }

  componentDidMount() {
    this.api = new Api();
  }

  getComboxValue = (value) => {
    this.setState({
      target: value,
    });
  };

  save = () => {
    if (this.state.target !== undefined) {
      this.props.setLoading({
        status: true,
        theme: LoadingStyles.theme.dark,
      });
      const { filename, config, projectId } = this.props;
      const formdata = new FormData();
      formdata.append('projectId', projectId);
      formdata.append('filename', window.btoa(unescape(encodeURIComponent(filename.substring(0, filename.lastIndexOf('.'))))));
      formdata.append('detail', JSON.stringify({
        id: config.id,
        redirectTarget: this.state.target,
      }));
      Promise.all([this.api.sendRequest({
        url: url.ADD_SVG_DETAIL,
        method: 'put',
        body: formdata,
        errorThrow: true,
      }), new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 1000);
      })]).then((results) => {
        const res = results[0];
        this.props.setLoading({
          status: false,
        });
        if (res.ok && res.body.code === 0) {
          this.props.complete();
        } else {
          this.props.setMessage({
            status: true,
            text: '更新数据点时出错了',
          });
        }
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.props.setMessage({
          status: true,
          text: '出错了',
        });
      });
    }
  };

  render() {
    return <div className={styles.main} onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
    }}>
      <div className={styles.item}>
        <ComboBox getValue={this.getComboxValue} data={this.props.projectSvgList.map((svg) => ({
          key: svg,
          name: Tools.formatFilename(svg)[0],
        }))} value={this.state.target}/>
      </div>
      <div>
        <Button onClick={this.save} text={'保存'}/>
      </div>
    </div>;
  }
}

DeviceSet.propTypes = {
  show: PropTypes.bool.isRequired,
  projectSvgList: PropTypes.array.isRequired,
  switchMenu: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  complete: PropTypes.func.isRequired,
};

export default DeviceSet;
