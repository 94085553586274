import TYPES from './message-box.types';
import MessageStyles from '../../entity/message';

const defaultState = {
  status: false,
  theme: MessageStyles.theme.error,
  text: '出错了',
};

const messageAction = (state) => (dispatch) => {
  const currentState = {
    ...defaultState,
    ...state,
  };
  if (currentState.status) {
    dispatch({
      type: TYPES.SHOW_MESSAGE,
      data: currentState,
    });
    setTimeout(() => {
      dispatch({
        type: TYPES.HIDE_MESSAGE,
      });
    }, 3000);
  } else {
    dispatch({
      type: TYPES.HIDE_MESSAGE,
      data: currentState,
    });
  }
};

export default {
  messageAction,
};
