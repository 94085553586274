import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './circle.less';
import LoadingStyles from '../../../entity/loading';

class CircleLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className={styles.panel} onClick={(event) => event.stopPropagation()}>
      <div className={styles.main} style={{
        background: this.props.theme === 'light' ? 'rgba(140, 132, 132, 0.5)' : 'rgba(26, 45, 45, 0.7)',
      }}>
        {
          new Array(8).fill(0).map((item, index) => (
            <div className={`${styles.item} ${styles['item' + index]}`} key={index}/>
          ))
        }
      </div>
    </div>;
  }
}

CircleLoading.propTypes = {
  theme: PropTypes.oneOf(Object.entries(LoadingStyles.theme).map((style) => style[1])).isRequired,
};

export default CircleLoading;
