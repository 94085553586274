import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './svg-list.less';
import Tools from '../../utils/tools';
import SvgListMenu from './context-menu/svg-list-menu';
import ConfirmPanel from '../confirm-panel/confirm-panel';

class SvgList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contextMenu: {
        top: 0,
        left: 0,
      },
      showContextMenu: false,
      showConfirm: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.hideMenu);
    this.currentSvg = '';
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideMenu);
  }

  contextMenu = (event, svg) => {
    if (this.props.removeSvg !== undefined) {
      event.preventDefault();
      event.stopPropagation();
      let clickX = event.clientX;
      let clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const menuWidth = Tools.remRadio(100);
      const menuHeight = Tools.remRadio(40);
      if (clickX + menuWidth > screenW) {
        clickX -= menuWidth;
      }
      if (clickY + menuHeight > screenH) {
        clickY -= menuHeight;
      }
      this.setState({
        showContextMenu: true,
        contextMenu: {
          top: clickY,
          left: clickX,
        },
      });
      this.currentSvg = svg;
    }
  };

  hideMenu = () => {
    this.setState({
      showContextMenu: false,
    });
  };

  removeSvg = () => {
    if (this.currentSvg !== '') {
      this.props.removeSvg(this.currentSvg.replace(/\.svg/g, '')).then(() => {
        this.switchConfirm(false);
      });
    }
  };

  switchConfirm = (flag) => {
    if (!flag) {
      this.currentSvg = '';
    }
    this.setState({
      showContextMenu: false,
      showConfirm: flag,
    });
  };

  render() {
    return <div className={styles.svgBox}>
      {
        this.props.svgList.map((svg, index) => (
          <div
            className={`${styles.svgItem} ${this.props.currentSvg !== undefined && this.props.currentSvg === svg ? styles.active : ''}`}
            key={index} onClick={() => this.props.onClick(svg)} onContextMenu={(event) => this.contextMenu(event, svg)}>
            <div className={styles.filename}>
              {
                Tools.formatFilename(svg)[0]
              }
            </div>
            <div className={styles.time}>
              {
                Tools.formatFilename(svg)[1]
              }
            </div>
          </div>
        ))
      }
      {
        this.state.showContextMenu && (
          <SvgListMenu top={this.state.contextMenu.top} left={this.state.contextMenu.left} onClick={() => this.switchConfirm(true)}/>)
      }
      {
        this.state.showConfirm && (
          <ConfirmPanel cancel={() => this.switchConfirm(false)} confirm={this.removeSvg} text={`确认删除 [${Tools.formatFilename(this.currentSvg)[0]}] 吗？`}/>
        )
      }
    </div>;
  }
}

SvgList.propTypes = {
  svgList: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  currentSvg: PropTypes.string,
  removeSvg: PropTypes.func,
};

export default SvgList;
